import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { AmplifyService } from 'aws-amplify-angular';
import { AuthenticationService } from '../helpers/services';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";
import { map, startWith } from 'rxjs/operators';
import { Storage } from 'aws-amplify';
import { Persona } from '../helpers/objects/persona';
import { DatabaseService } from '../helpers/services';
import { Account } from '../helpers/objects/account';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {

  data = {
    accounts: [
      {
        domain: "",
        username: "",
        password: ""
      }
    ]
  };

  personaInfoForm: FormGroup;
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  domains: string[] = ['Gmail', 'Outlook', 'Facebook','LinkedIn', 'Twitter', 'Instagram',
    'Buffer', 'Yahoo', 'Pinterest', 'Wickr', 'Whatsapp', 'AOL', 'About.Me',
    'Canva', 'iCloud', 'Blur'].sort(function(a, b) {
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
    });
  types = ['Digital', 'Physical'];
  genders = ['Female', 'Male'];
  regionList = ['Miami', 'NYC', 'Atlanta', 'Boston', 'Chicago', 'Dallas', 'HQS', 'Kansas-City', 'Los-Angeles', 'Philadelphia', 'San-Francisco', 'blackhorse', 'Super-Admin'];
  username: string;
  group = this.authenticationService.currentUserValue.groups;
  region: string;
  selectedUser: string;
  hhsList = ['Miami', 'NYC', 'Atlanta', 'Boston', 'Chicago', 'Dallas', 'HQS', 'Kansas-City', 'Los-Angeles', 'Philadelphia', 'San-Francisco', 'Super-Admin'];

  PERSONA_API = 'chameleonDBAPI';
  DATABASE_API = 'InceptionDatabaseApi'
  profilePicPath: string = '';

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private amplifyService: AmplifyService,
              private authenticationService: AuthenticationService,
              public snackBar: MatSnackBar,
              private databaseService: DatabaseService) {
    this.region = localStorage.getItem('region');
    this.username = localStorage.getItem('username');
    this.personaInfoForm = this.createFormGroup(formBuilder);
    this.selectedUser = this.personaInfoForm['user'];
    this.setAccounts();
  }

  /**
   * Method creating a form for personas information
   */
  createFormGroup(formBuilder: FormBuilder) {   
    return formBuilder.group({
      firstName: [''],
      lastName: [''],
      currentLoc: [''],
      mailingAdd: [''],
      pob: [''],
      type: [''],
      dob: [''],
      phone: [''],
      pin: [''],
      modelNum: [''],
      serialNum: [''],
      phoneNetwork: [''],
      imei: [''],
      gender: [''],
      hs: [''],
      college: [''],
      work: [''],
      hobbies: [''],
      user: [this.username],
      checkedOut: [false],
      regions: [this.region],
      accounts: this.formBuilder.array([]),
      logs: this.formBuilder.array([]),
      notes: this.formBuilder.array([])
    })
  }

  ngOnInit() {
    this.deleteAcct(0);
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.domains.filter(option=> option.toLowerCase().indexOf(filterValue) === 0);
  }

  uploadProfilePic(pic, firstName: string, lastName: string) {
    if (pic) {
      let name = this.username + '_' + firstName + ' ' + lastName;
      if (firstName == '' || lastName == '') {
        return;
      }
      let date: Date = new Date();
      let now: number = date.getTime();
      let fileName = name.replace(/ /g, '') + '_' + now + '/profilepic.png';
      this.profilePicPath = fileName;
      Storage.put(fileName, pic.files[0]).catch(err => console.log(err));
    }
  }

  /**
   * this function is used through the entire create persona form. it is constantly saving
   */
  async onSubmit() {
    let persona: Persona = new Persona();
    let result = this.personaInfoForm.value;
    let given_user = result.user;
    let input_name = result.firstName.trim() + ' ' + result.lastName.trim();

    if (result.firstName === '' || result.lastName === '') {
      alert('A first and last name is required.');
      return;
    }

    // Saving the accounts and it can not be empty
    let accounts = result.accounts;
    for (let i of accounts) {
      if (i.domain === '' || i.username === '' || i.password === '') {
        alert('This avatar\'s accounts are missing either a domain, username, or password and must be added first.');
        return;
      }
    }
    
    if (!input_name.toString().includes('_')) {
      result.name = given_user + '_' + result.firstName + ' ' + result.lastName;
    }

    if (result.accounts.length > 0 && result.accounts[0] == {}) {
      result.accounts.shift();
    }

    persona = this.convertResultToPersona(result);
    let createPersonaResponse = await this.databaseService.createPersona(persona);

    if (createPersonaResponse && persona.accounts.length > 0) {
      for (let account of persona.accounts) {
        account.personaId = createPersonaResponse.data.insertId;
        await this.databaseService.createAccount(account);
      }
    }

    return createPersonaResponse;
  }

  /** 
   * Follows the button save And Exit.
   */
  saveAndExit() {
    this.onSubmit().then(result => {
      if (result) {
        this.router.navigate(['/avatars']);
        this.snackBar.open('Avatar saved.', 'Close', {duration: 1000});
      }
    });
  }

  /**
   * This button does not save a persona but takes it out of this page and to all personas page
   */
  exitPersona() {
    let isSaved = confirm("Exit the avatar creation page without saving.");
    if (isSaved)
      this.router.navigate(['/avatars']);
  }

  /**
   * This creates new accounts and pushes this account to the control.
   */ 
  addNewAccount() {
    let control = <FormArray>this.personaInfoForm.controls.accounts;
    control.push(
      this.formBuilder.group({
        domain:[''],
        username:[''],
        password:['']
      })
    )
  }

  /**
   * deleting an account for the array and formArray
   */
  deleteAcct(index) {
    let control = <FormArray> this.personaInfoForm.controls.accounts;
    control.removeAt(index);
  }

  /**
   * This sets the accounts up and stores what is inputted into each input
   */
  setAccounts() {
    let control = <FormArray>this.personaInfoForm.controls.accounts;
    this.data.accounts.forEach(x => {
      control.push(this.formBuilder.group({
        domain: x.domain,
        username: x.username,
        password: x.password
      }))
    })
  }

  validForm(): boolean {
    let valid: boolean = false;
    if (this.personaInfoForm.value.firstname !== '' && this.personaInfoForm.value.lastName !== '') {
      valid = true;
    }
    return valid;
  }

  convertResultToPersona(result: any): Persona {
    let persona: Persona = new Persona();
    let accounts: Account[] = [];
    if (result.accounts.length > 0) {
      for (let account of result.accounts) {
        let accountTemp: Account = new Account();
        accountTemp.domain = (account.domain ? account.domain : '');
        accountTemp.username = (account.username ? account.username : '');
        accountTemp.notes = (account.notes ? account.notes : '');
        accountTemp.password = (account.password ? account.password : '');
        accounts.push(account);
      }
    }
    persona.checkedOut = result.checkedOut;
    persona.college = result.college;
    persona.currentLocation = result.currentLoc;
    persona.dob = result.dob;
    persona.firstName = result.firstName;
    persona.gender = result.gender;
    persona.hobbies = result.hobbies;
    persona.hs = result.hs;
    persona.imei = result.imei;
    persona.lastName = result.lastName;
    persona.mailingAddress = result.mailingAdd;
    persona.modelNumber = result.modelNum;
    persona.name = result.name;
    persona.phone = result.phone;
    persona.phoneNetwork = result.phoneNetwork;
    persona.pin = result.pin;
    persona.pob = result.pob;
    persona.region = result.regions;
    persona.serialNumber = result.serialNum;
    persona.type = result.type;
    persona.user = result.user;
    persona.work = result.work;
    persona.accounts = accounts;
    persona.profilePicPath = this.profilePicPath;

    return persona;
  }
}
