import { Account } from "./account";

export class Persona {
    id: number;
    name: string;
    firstName: string;
    lastName: string;
    user: string;
    checkedOut: boolean;
    college: string;
    currentLocation: string;
    dob: any;
    gender: string;
    hobbies: string;
    hs: string;
    imei: string;
    mailingAddress: string;
    modelNumber: string;
    phone: string;
    phoneNetwork: string;
    pin: string;
    pob: string;
    region: string;
    serialNumber: string;
    type: string;
    work: string;
    accountCount: number;
    accounts: Account[] = [];
    created: Date;
    edited: Date;
    profilePicPath: string;

    constructor() {}
}
