import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';

Amplify.configure({...awsconfig,
  /*
  Auth: {
    identityPoolId: 'cognitob4757bd5_identitypool_92dcc309__dev',
    region: 'us-east-1'
  },

   */

  Storage: {
    AWSS3: {
      bucket: 'chameleon-persona-logs', //REQUIRED -  Amazon S3 bucket
      region: 'us-east-1', //OPTIONAL -  Amazon service region
    }
  }
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
