import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Storage } from 'aws-amplify';
import { AmplifyService } from 'aws-amplify-angular';
import { AuthenticationService } from '../helpers/services';
import { MatPaginator, MatSnackBar, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-logs-upload',
  templateUrl: './logs-upload.component.html',
  styleUrls: ['./logs-upload.component.scss']
})
export class LogsUploadComponent implements OnInit {

  @Input() persona_name: string;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  addingNewLog: boolean = false;
  PERSONA_API = 'chameleonDBAPI';
  usernamePersonaHash: string;
  logs: LogElement[] = [];
  dataSource: any;
  displayedColumns: string[] = ['uploader', 'date', 'time', 'uploadType', 'media', 'comments'];

  constructor(private authenticationService: AuthenticationService,
              private amplifyService: AmplifyService,
              private mySnackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.usernamePersonaHash = this.persona_name.replace(/ /g,'');

    this.loadLogs().then(result =>
      this.dataSource.paginator = this.paginator
    );
  }

  getLogLength() {
    return this.logs.length;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * Loads logs and initializes them for use in the log table
   * Objects of the logs array are of LogElement type
   */
  async loadLogs() {
    this.logs = [];

    let response = await this.amplifyService.api().get(this.PERSONA_API, '/avatar');
    if (response) {
      for (let ppl of response) {
        if (ppl['name'] === this.persona_name) {
          ppl.logs.reverse();

          for (let entry of ppl.logs) {
            let singleLog = entry;
            this.logs.push(new LogElement(
              singleLog['user'] || 'user',
              this.persona_name,
              singleLog['dateTime'].split('_')[0],
              singleLog['dateTime'].split('_')[1],
              singleLog['type'] || 'Session',
              singleLog['media'],
              singleLog['text']
            ));
          }
        }
      }
    }

    this.dataSource = new MatTableDataSource<LogElement>(this.logs);
  }

  logging() {
    this.addingNewLog = !this.addingNewLog;
  }

  async uploadFile(logMedia:any, logText: any): Promise<Object> {
    if (!logMedia.value && !logText) {
      alert('Both fields cannot be blank');
      return;
    }

    try {
      //-------------Starting to upload to s3 bucket
      let dateTime = this.getDate();
      let fileName = this.usernamePersonaHash + '/' + dateTime + '/' + this.createFileName(dateTime);
      let mediaResponse = null;
      let logResponse = null;

      // If there is a media attachment then put
      if (logMedia.value) {
        let mediaFileType = String(logMedia.files[0].type).split('/')[1];
        let fullFileName = fileName + '.' + mediaFileType;
        mediaResponse = await Storage.put(fullFileName, logMedia.files[0]);
      }
      // If there is a text log attachment then put
      if (logText) {
        logResponse = await Storage.put(fileName + '.txt', logText);
      }

      //-------------Starting to store keys in logs array of persona
      // If there is a media file and text file
      if (logMedia.value && logText) {
        this.addEvidenceLog(this.persona_name, dateTime, mediaResponse['key'], logResponse['key']).then(result => this.loadLogs()).then(out =>{
          this.mySnackBar.open('Logs Uploaded Successfully','Cancel',{duration: 2000});
          this.addingNewLog = false;
        });
      }
      // IF there is only a media file
      else if (logMedia.value) {
        this.addEvidenceLog(this.persona_name, dateTime, mediaResponse['key'], null).then(result => this.loadLogs()).then(out => {
          this.mySnackBar.open('Logs Uploaded Successfully','Cancel',{duration: 2000});
          this.addingNewLog = false;
        });
      }
      // If there is only a text file
      else {
        this.addEvidenceLog(this.persona_name, dateTime, null, logResponse['key']).then(result => this.loadLogs()).then(out => {
          this.mySnackBar.open('Logs Uploaded Successfully','Cancel',{duration: 2000});
          this.addingNewLog = false;
        });
      }
    }
    catch (e) {
      console.log("error uploading file:", e);
    }

    return;
  }

  createFileName(time) {
    return this.usernamePersonaHash + '_' + time;
  }

  /**
   * Gets current date and time formatted
   * example return: 2019.06.26_09:50:10
   */
  getDate(): string {
    let d = new Date();
    // Getting Date in consistent padding format
    let datestring = d.getFullYear() + "." +
      ("0" + (d.getMonth()+1)).slice(-2) + "." +
      ("0" + d.getDate()).slice(-2) + "_" +
      ("0" + d.getHours()).slice(-2) + ":" +
      ("0" + d.getMinutes()).slice(-2) + ":" +
      ("0" + d.getSeconds()).slice(-2);

    return datestring;
  }

  /**
   * Creates evidence log to be displayed in table of logs
   * All logs have user, type, and dateTime
   * Logs can have media, text, or nothing if it is a check in/out
   *
   * @param inputName
   * @param time
   * @param mediaLink : Key given by Amplify Storage put
   * @param textLink : Key given by Amplify Storage put
   */
  async addEvidenceLog(inputName: string, time: string, mediaLink: string, textLink: string) : Promise<Object> {
    try {
      let response = await this.amplifyService.api().get(this.PERSONA_API, '/avatar');
      if (response) {
        for (let ppl of response) {
          if (ppl['name'] === inputName) {
            if (mediaLink && textLink) {
              ppl['logs'].push({'user': this.authenticationService.currentUserValue.username, 'type': 'Session', 'dateTime': time, 'media': mediaLink, 'text': textLink});
            } else if (mediaLink) {
              ppl['logs'].push({'user': this.authenticationService.currentUserValue.username, 'type': 'Session', 'dateTime': time, 'media': mediaLink});
            } else {
              ppl['logs'].push({'user': this.authenticationService.currentUserValue.username, 'type': 'Session', 'dateTime': time, 'text': textLink});
            }

            const path = '/avatar';
            const init = { body: ppl };
            return this.amplifyService.api().post(this.PERSONA_API, path, init);
          }
        }
      } else {
        console.log('logs link upload response failed')}
      } catch (e) {
        console.log('err adding log' + e);
      }
      return;
    }
  }
  

  /**
   * Class for log table elements
   * Has method fof download links
   */
  export class LogElement {
    uploader: string;
    persona: string;
    date: string;
    time: string;
    type: string;
    media: string;
    comments: string;

    constructor(uploader: string, persona:string, date: string, time: string, type: string, media: string, comments: string) {
      this.uploader = uploader;
      this.persona = persona;
      this.date = date;
      this.time = time;
      this.type = type;
      this.media = media;
      this.comments = comments;
    }

    downloadFile(url):void {
      Storage.get(url).then(result => this.createDownload(String(result)));
    }
  
    private createDownload(url) {
      window.open(url, '_blank');
    }
  }
  
