import { Component, OnInit, } from '@angular/core';
import { AuthenticationService, DatabaseService } from "../helpers/services";
import { AmplifyService } from "aws-amplify-angular";
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label, MultiDataSet } from 'ng2-charts';
import * as jspdf from "jspdf";
import 'jspdf-autotable';
import { animate, state, style, transition, trigger } from '@angular/animations';
import html2canvas from 'html2canvas';
import {Log} from '../helpers/objects/log';
import { LogElement } from '../logs-upload/logs-upload.component';
import { Persona } from '../helpers/objects/persona';
import { Account } from '../helpers/objects/account';
import { DatePipe } from '@angular/common';

interface LogCount {
  name: string,
  count: number
}

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
  providers: [DatePipe],
  animations: [
    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateX(0)' })),
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('0.5s 300ms ease-in')
      ]),
      transition(':leave', [
        animate('0.3s ease-out', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class MetricsComponent implements OnInit {

  allDataLoaded: boolean = false;
  // Data Usage Constants
  userClass: USER_CLASS;
  PERSONA_API = 'chameleonDBAPI';

  // User info
  group: string;
  username: string;
  region: string = localStorage.getItem('region');
  // Statistic Data
  totalPersonaCount: number; //number of personas at current level
  physicalPersonaCount: number;
  digitalPersonaCount: number;
  totalPersonasCheckedIn: number;
  totalPersonasCheckedOut: number;
  personaRegions: object;
  personaLogsUsed: {name: string, number: number}[];
  personaAccountsUsed;
  allPersonaLogs: Log[] = [];
  // Checking Chart Variables
  personaTypeChartLabels: Label[];
  personaTypeChartData: MultiDataSet;
  checkedOutChartLabels: Label[];
  checkedOutChartData: MultiDataSet;
  // Account Chart Variables
  accountsChartLabels: Label[];
  accountsChartData: MultiDataSet;
  accountsChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
      fullWidth: false
    },
  };
  accountsChartColors = [
    {
      backgroundColor: [],
    }
  ];
  //Log Chart Variables
  logsChartLabels: Label[];
  logsChartData: ChartDataSets[];
  barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{
        barThickness: 50,
        ticks: {
          min: 0
        }
      }],
      yAxes: [{
        ticks: {
          min: 0
        }
      }]
    }
  };
  // Map Data
  mapDataSource: Object;

  constructor(private authenticationService: AuthenticationService,
              private amplifyService: AmplifyService,
              private databaseService: DatabaseService,
              private datePipe: DatePipe
              ) {

    this.totalPersonaCount = 0;
    this.physicalPersonaCount = 0;
    this.digitalPersonaCount = 0;
    this.totalPersonasCheckedIn = 0;
    this.totalPersonasCheckedOut = 0;
    this.personaRegions = {
      'blackhorse': 0,
      'Atlanta': 0,
      'Boston': 0,
      'Chicago': 0,
      'Dallas': 0,
      'HQS': 0,
      'Kansas-City': 0,
      'Los-Angeles': 0,
      'Miami': 0,
      'NYC': 0,
      'Philadelphia': 0,
      'San-Francisco': 0
    };
    this.personaLogsUsed = [];
    this.personaAccountsUsed = [];
    this.personaTypeChartLabels = ['Physical Avatars', 'Digital Avatars'];
    this.personaTypeChartData = [[0, 0]];
    this.checkedOutChartLabels = ['Checked In', 'Checked Out'];
    this.checkedOutChartData = [[0, 0]];
    this.accountsChartLabels = [];
    this.accountsChartData = [[]];
    this.logsChartLabels = [];
    this.logsChartData = [{ data: [0, 0, 0], label: 'Most Used Avatars' }];
    let groups = this.authenticationService.currentUserValue.groups;

    // Setting the user class which controls what personas get shown for the statistics
    if (groups.includes('Chameleons'))
      this.userClass = USER_CLASS.CHAMELEON;
    else if (groups.includes('Regional-Managers'))
      this.userClass = USER_CLASS.MANAGER;
    else if (groups.includes('Super-Admin'))
      this.userClass = USER_CLASS.ADMIN;
    else if (groups.includes('JoeFieldSuper-Admin'))
      this.userClass = USER_CLASS.JOE_ADMIN;

    this.initMetricData(this.userClass).then( result => {
      // Initializing Physical/Digital Chart
      this.personaTypeChartData = [[this.physicalPersonaCount, this.digitalPersonaCount]];

      // Initializing Checked In/Out Chart
      this.checkedOutChartData = [[this.totalPersonasCheckedIn, this.totalPersonasCheckedOut]];

      // Initializing accounts chart
      for (let key in this.personaAccountsUsed) {
        this.accountsChartLabels.push(key);
        this.accountsChartData[0].push(this.personaAccountsUsed[key]);
        this.accountsChartColors[0].backgroundColor.push(`rgba(${Math.floor(Math.random() * 256)},
        ${Math.floor(Math.random() * 256)},
        ${Math.floor(Math.random() * 256)},0.3)`);
      }

      // Sorting entries by the date and time
      // Used to sort LogElement, but likely switching to Log and not needed

      // this.allPersonaLogs.sort((left, right): number => {
      //   if (left.date+left.time > right.date+right.time) {
      //     return -1;
      //   } else if (left.date+left.time < right.date+right.time) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // });

      //Initializing log chart
      let logData:{name: string, number: string}[] = this.findMostLoggedPersonas();
      this.logsChartLabels = [
        logData['first'].name.split('_')[1] || '',
        logData['second'].name.split('_')[1] || '', 
        logData['third'].name.split('_')[1] || ''];
      this.logsChartData = [{ data: [
        Math.round(logData['first'].number), 
        Math.round(logData['second'].number),
        Math.round(logData['third'].number)], 
        label: 'Logs' 
      }];
        
      this.exportMetricDataAsText();
      
      this.mapDataSource = {
        chart: {
          animation: "0",
          showbevel: "0",
          usehovercolor: "1",
          canvasbordercolor: "FFFFFF",
          bordercolor: "FFFFFF",
          caption: "National Avatar Metrics",
          connectorcolor: "000000",
          fillalpha: "80",
          hovercolor: "CCCCCC",
          showborder: 0,
          theme: "fusion",
          showlegend: "0",
        },
        data: [
          { id: "CA", value: this.personaRegions['Los-Angeles'] },
          { id: "PA", value: this.personaRegions['Philadelphia'] },
          { id: "MA", value: this.personaRegions['Boston'] },
          { id: "IL", value: this.personaRegions['Chicago'] },
          { id: "TX", value: this.personaRegions['Dallas'] },
          { id: "VA", value: this.personaRegions['HQS'] },
          { id: "MO", value: this.personaRegions['Kansas-City'] },
          { id: "FL", value: this.personaRegions['Miami'] },
          { id: "NY", value: this.personaRegions['NYC'] },
          { id: "GA", value: this.personaRegions['Atlanta'] },
          { id: "CA", value: this.personaRegions['San-Francisco'] },
        ],
      };
    }).then(result =>  {
      this.allDataLoaded = true;
      
      document.getElementById('opacity-low').style.opacity = '1';
    }).catch(err => console.log('Could not init Dashboard'));
  }

  ngOnInit() {
    this.loadLogs()
  }

  async loadLogs() {
    this.allPersonaLogs = [];
    //returns 2 or 3 currently if admin
    if (this.userClass == 2 || this.userClass == 3) {
      this.allPersonaLogs = await this.databaseService.getAllLogs();
    } else {
      //This doesn't work, seems to be caused by sql syntax issue. Investigate the backend
      this.allPersonaLogs = await this.databaseService.getLogsByRegion(this.region);
    }
  }

  /**
   * Initializes all basic metric data based on the user class
   * Different userClasses will get different scopes of data
   * user -> personal stats
   * manager -> region stats
   * admin -> national stats
   */
  async initMetricData(userClass: USER_CLASS) {
    try {
      let personas: Persona[] = [];
      let accounts: Account[] = [];
      let logs: LogCount[] = [];

      if (this.region === 'admin') {
        personas = await this.databaseService.getAllPersonas();
        accounts = await this.databaseService.getAllAccounts();
        logs = await this.databaseService.getLogCounts();
        this.totalPersonaCount = personas.length;
      } else {
        personas = await this.databaseService.getPersonasByRegion(this.region);
        accounts = await this.databaseService.getAccountsByRegion(this.region);
        logs = await this.databaseService.getLogCountsByRegion(this.region);
        this.totalPersonaCount = personas.length;
      }

      // Initiate Account Information
      if (accounts) {
        for (let acc of accounts) {
          let accName = acc.domain;
          if (accName == null) { continue; }
          if (this.personaAccountsUsed.hasOwnProperty(accName)) {
            this.personaAccountsUsed[accName]++;
          } else {
            this.personaAccountsUsed[accName] = 1;
          }
        }
      }

      for (let persona of personas) {
        // Initiate Persona type Information
        if (persona.type === 'Digital') {
          this.digitalPersonaCount++;
        } else if (persona.type === 'Physical') {
          this.physicalPersonaCount++;
        }

        // See if its checked in or out
        if (persona.checkedOut === true) {
          this.totalPersonasCheckedOut++;
        } else {
          this.totalPersonasCheckedIn++;
        }

        // Get Region data
        if (this.personaRegions.hasOwnProperty(persona.region)) {
          this.personaRegions[persona.region]++;
        } else  {
          this.personaRegions[persona.region] = 1;
        }
      }

      // Populate logs
      for (let log of logs) {
        this.personaLogsUsed.push({name : log.name, number : log.count});
      }
    }
    catch (error) {
      console.log("error");
    }
  }

  /**
   * Looping through all the personas that have been parsed for whatever level
   * and finding which are the top three personas that have been logged
   */
  private findMostLoggedPersonas() :  {name: string, number: string}[] {
    let topThree = [];
    topThree['first'] = {name: ' ', number: 0};
    topThree['second'] = {name: ' ', number: 0};
    topThree['third'] = {name: ' ', number: 0};

    for (let persona of this.personaLogsUsed) {
      let p = {name: persona.name, number: persona.number};

      if (p.number > topThree['first'].number) {
        let temp = {name: topThree['first'].name, number: topThree['first'].number};
        topThree['first'] = {name: p.name, number: p.number};
        p = temp;
      }
      if (p.number > topThree['second'].number) {
        let temp = {name: topThree['second'].name, number: topThree['second'].number};
        topThree['second'] = {name: p.name, number: p.number};
        p = temp;
      }
      if (p.number > topThree['third'].number) {
        let temp = {name: topThree['third'].name, number: topThree['third'].number};
        topThree['third'] = {name: p.name, number: p.number};
        p = temp;
      }
    }

    return topThree;
  }

  /**
   * Taking a screenshot of all the statistics on the page and saving it on
   * clients pc
   */
  public exportMetricDataAsPDF() {
    let data = document.getElementById('metrics-content');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      let imgWidth = 208;
      let imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      pdf.addImage(contentDataURL, 'PNG', 0,0, imgWidth, imgHeight);
      pdf.save(localStorage.getItem('username') + '_metrics.pdf'); // Generated PDF
    });
  }

  /**
   * Loads all logs shown in the logs section of the metrics and puts them into
   * a pdf and saves it to the clients pc.
   * All logs will be organized by date newest to oldest.
   */

  getDate(dateTime: Date): string {
    let date = new Date(dateTime);
    return this.datePipe.transform(date, 'MM-dd-yyy');
  }
  
  getTime(dateTime: Date): string {
    let time = new Date(dateTime);
    return (time.getHours() % 12) + ':' + (time.getMinutes()) + ':' 
      + (time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds()) + ' ' + 
      (time.getHours() >= 12 ? 'PM': 'AM');
  }


  exportPDF() {
    let head = [['Uploader', 'Name', 'Date', 'Time', 'Type', 'Comments']];
    let data = [];
    let currentTimestamp: Date = new Date();
    let fileName = 'Avatar_log_activity' + currentTimestamp.getTime() + '_' + '.pdf';
    let currentTimeString = currentTimestamp.toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit',
      day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })
    let title = 'Log Export on ' + currentTimeString;

    for (let log of this.allPersonaLogs) {
      let row = [ log.user, log.name.split('_')[1], this.getDate(log.dateTime), this.getTime(log.dateTime), log.media ? 'yes': 'no', log.text ? 'yes': 'no' ];
      data.push(row);
    }

    var doc = new jspdf();

    doc.setFontSize(12);
    doc.text(title, 11, 8);
    doc.setFontSize(8);
    doc.setTextColor(100);

    (doc as any).autoTable({
      head: head,
      body: data,
      theme: 'plain',
      didDrawCell: data => {}
    })

    // Download PDF document
    doc.save(fileName);
  }

  /**
   * All metrics exported as text
   * Currently not inuse per Joe's request
   */
  public exportMetricDataAsText() {
    let obj = this.metricJSON();
    let data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj));

    let a = document.createElement('a');
    a.id = 'all-logs';
    a.href = 'data:' + data;
    a.download = localStorage.getItem('username') + '_metrics.json';

    let container = document.getElementById('export-text');
    container.appendChild(a);
  }

  public merticCLick() {
    document.getElementById('metrics-json').click();
  }

  /**
   * Exporting metrics as a json
   */
  private metricJSON() : object {
    let data: object = {};
    data['region'] = this.region;
    data['totalNumberOfPersonas'] = this.totalPersonaCount;
    data['numberOfPhysicalPersonas'] = this.physicalPersonaCount;
    data['numberOfDigitalPersonas'] = this.digitalPersonaCount;
    data['mostLogged'] = this.personaLogsUsed;
    data['accountsUsed'] = this.personaAccountsUsed;

    return data;
  }

}

// Setting the user class based on what work role they are
enum USER_CLASS {
  CHAMELEON,
  MANAGER,
  ADMIN,
  JOE_ADMIN
}

