import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, DatabaseService } from '../helpers/services';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Log } from '../helpers/objects/log';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-logs-display',
  templateUrl: './logs-display.component.html',
  styleUrls: ['./logs-display.component.scss'],
  providers: [DatePipe]
})
export class LogsDisplayComponent implements OnInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  username: string;
  region: string;
  logs: Log[] = [];
  public group = this.authenticationService.currentUserValue.groups;
  dataSource: any;
  displayedColumns: string[] = ['uploader', 'persona', 'date', 'time', 'uploadType', 'media', 'comments'];

  constructor(private authenticationService: AuthenticationService, 
    private databaseService: DatabaseService,
    private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.username = localStorage.getItem('username');
    this.region = localStorage.getItem('region');    

    this.loadLogs().then(result => 
      this.dataSource.paginator = this.paginator)
  }

  /**
   * Loads logs and initializes them for use in the log table
   * Objects of the logs array are of Log type
   */
  async loadLogs() {
    this.logs = [];
    if (this.group[0].includes('Super-Admin')) {
      this.logs = await this.databaseService.getAllLogs();
    } else {
      this.logs = await this.databaseService.getLogsByRegion(this.region);
    }
    this.dataSource = new MatTableDataSource<Log>(this.logs);
  }

  getDate(dateTime: string): string {
    let date = new Date(dateTime);
    return this.datePipe.transform(date, 'MM-dd-yyy');
  }
  
  getTime(dateTime: string): string {
    let time = new Date(dateTime);
    return (time.getHours() % 12) + ':' + (time.getMinutes()) + ':' 
      + (time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds()) + ' ' + 
      (time.getHours() >= 12 ? 'PM': 'AM');
  }
}
