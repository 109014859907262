import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { AmplifyService } from 'aws-amplify-angular';
import { AuthenticationService } from '../helpers/services';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Storage } from 'aws-amplify';
import { DatabaseService } from '../helpers/services/database.service';
import { Persona } from '../helpers/objects/persona';
import { Account } from '../helpers/objects/account';
import { AccountsCleanup } from 'aws-sdk/clients/devicefarm';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-view-edit-page',
  templateUrl: './view-edit-page.component.html',
  styleUrls: ['./view-edit-page.component.scss']
})

export class ViewEditPageComponent implements OnInit, OnDestroy {

  /**
   * Setting up data as the object array filled with objects called accounts. 
   * each account has three attributes, domain, username, and password which ties to the personas i=online information
   */
  data = {
    accounts: [
      {
        domain: '',
        username: '',
        password: ''
      }
    ]
  };

  personaInfoForm: FormGroup;
  // Domains sorted alphabetically
  domains=['Gmail', 'Outlook', 'Facebook','LinkedIn', 'Twitter', 'Instagram',
    'Buffer', 'Yahoo', 'Pinterest', 'Wickr', 'Whatsapp', 'AOL', 'About.Me',
    'Canva', 'iCloud', 'Blur'].sort(function(a, b) {
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
  });
  types = ['Digital', 'Physical']; // A persona can be digital or physical
  genders = ['Female', 'Male']; // Persona genders
  PERSONA_API = 'chameleonDBAPI'; // API that is being used in AWS
  public user = this.authenticationService.currentUserValue.username;
  public name: any;
  public id: number;
  tempLogArr: any[] = [];
  tempNoteArr: any[]=[];
  regionList = ['Miami', 'NYC', 'Atlanta', 'Boston', 'Chicago', 'Dallas', 'HQS', 'Kansas-City', 'Los-Angeles', 'Philadelphia', 'San-Francisco', 'blackhorse', 'Super-Admin'];
  region: string;
  group = this.authenticationService.currentUserValue.groups;
  hhsList=['Miami', 'NYC', 'Atlanta', 'Boston', 'Chicago', 'Dallas', 'HQS', 'Kansas-City', 'Los-Angeles', 'Philadelphia', 'San-Francisco', 'Super-Admin'];
  sub: any;
  isSelected: boolean = false;
  persona: Persona;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private amplifyService: AmplifyService,
              private authenticationService: AuthenticationService,
              public snackBar: MatSnackBar,
              private databaseService: DatabaseService) {
    this.personaInfoForm = this.createFormGroup(formBuilder);
    this.setAccounts();
  }

  /**
   * Method that creates the form group and its attributes that will contain the personas information
   */
  createFormGroup(formBuilder: FormBuilder) {
    return formBuilder.group({
      firstName: [''],
      lastName: [''],
      currentLoc: [''],
      mailingAdd: [''],
      pob: [''],
      type: [''],
      dob: [''],
      phone: [''],
      pin: [''],
      modelNum: [''],
      serialNum: [''],
      phoneNetwork: [''],
      imei: [''],
      gender: [''],
      hs: [''],
      college: [''],
      work: [''],
      hobbies: [''],
      user: [''],
      checkedOut: [''],
      regions: [''],
      logs: this.formBuilder.array([]),
      accounts: this.formBuilder.array([]),
      notes: this.formBuilder.array([])
    })
  }

  async ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.name = params.persona_name;
      this.id = params.id;
    });

    this.persona = await this.databaseService.getPersonaById(this.id);

    if (this.persona) {
      this.getPersonInfo();
    }
    this.deleteAcct(0);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  uploadProfilePic(pic, firstName: string, lastName: string) {
    if (pic) {
      if (firstName == '' || lastName == '') {
        return;
      }
      console.log(this.persona.profilePicPath);
      if (!this.persona.profilePicPath) {
        let name = this.user + '_' + firstName + ' ' + lastName;
        if (firstName == '' || lastName == '') {
          return;
        }
        let date: Date = new Date();
        let now: number = date.getTime();
        let fileName = name.replace(/ /g, '') + '_' + now + '/profilepic.png';
        this.persona.profilePicPath = fileName;
      }
      console.log(this.persona.profilePicPath);
      Storage.put(this.persona.profilePicPath, pic.files[0]).catch(err => console.log(err));
    }
  }

  /**
   * This method retrieves the personas information for editing.
   */
  async getPersonInfo(): Promise<FormData> {
    try {
      let accounts: Account[] = await this.databaseService.getAccountsByPersonaId(this.id);
      if (this.persona.id) {
        this.personaInfoForm.patchValue({
          firstName: this.persona.firstName,
          lastName: this.persona.lastName,
          type: this.persona.type,
          currentLoc: this.persona.currentLocation,
          mailingAdd: this.persona.mailingAddress,
          dob: this.persona.dob,
          pob: this.persona.pob,
          phone: this.persona.phone,
          pin: this.persona.pin,
          modelNum: this.persona.modelNumber,
          serialNum: this.persona.serialNumber,
          phoneNetwork: this.persona.phoneNetwork,
          imei: this.persona.imei,
          gender: this.persona.gender,
          hs: this.persona.hs,
          college: this.persona.college,
          work: this.persona.work,
          hobbies: this.persona.hobbies,
          regions: this.persona.region,
          checkedOut: this.persona.checkedOut,
          user: this.persona.user,
        })
      }

      if (accounts.length > 0) {
        for (let account of accounts) {
          let control = <FormArray>this.personaInfoForm.controls.accounts;
          control.push(
            this.formBuilder.group({
              domain: [account.domain],
              username: [account.username],
              password: [account.password]
            })
          )
        }
      }
    } catch (error) {
      console.log("error");
    }
    return this.personaInfoForm.value;
  }

  /**
   * this method is used through out the html to constantly be storing information
   */
  async onSubmit() {
    let result= this.personaInfoForm.value;
    this.name = result.firstName + ' ' + result.lastName;
    if (!result.firstName || !result.lastName) {
      alert('First and last name are required.');
      return;
    }
    if (!this.name.toString().includes('_')) {
      result.name = result.user + "_" + this.name;
    }
    let accounts = result['accounts'];
    for (let i of accounts) {
      // Account information can not be blank
      if (i.domain === '' || i.username === '' || i.password === '') {
        alert('This avatar\'s accounts are missing either a domain, username, or password and must be added first.');
        return;
      }
    }

    let persona = this.convertResultToPersona(result);
    return await this.databaseService.updatePersona(persona);
  }

  /**
   * This method allows the user to save and exit the page.
   */
  saveAndExit() {
    // Logs must be pushed back to the formbuilder
    for (let i of this.tempLogArr) {
      this.logs.push(this.formBuilder.control(i));
    }
    // Notes must be pushed back to the formbuilder
    for (let i of this.tempNoteArr) {
      this.notes.push(this.formBuilder.control(i));
    }

    // When onSubmit occurs the user is taken back to the personas page.
    this.onSubmit().then(result => {
      this.router.navigate([`/avatars/view/${this.name}/${this.id}`]);
      this.snackBar.open('Avatar Saved', 'Close', {duration: 1000})
    });
  }

  // Gets accounts from formArray
  get accounts(): FormArray {
    return this.personaInfoForm.get('accounts') as FormArray;
  }

  // Gets logs from formArray
  get logs(): FormArray{
    return this.personaInfoForm.get('logs') as FormArray;
  }

  // Gets notes from formArray
  get notes(): FormArray{
    return this.personaInfoForm.get('notes') as FormArray;
  }


  /**
   * This persona exits the page without updating the persona. All changes made will not be saved
   */
  exitPersona() {
    let isSaved = confirm("Hit OK if you saved before exiting the avatar creation page.");
    if (isSaved)
      this.router.navigate(['/avatars']);
  }

  /**
   * Method to add a new account to the existing persona
   */
  addNewAccount() {
    let control = <FormArray>this.personaInfoForm.controls.accounts;
    control.push(
      this.formBuilder.group({
        domain:[''],
        username:[''],
        password:['']
      })
    )
  }

  /**
   * Method to delete any accounts in the existing persona
   */
  deleteAcct(index) {
    let control = <FormArray> this.personaInfoForm.controls.accounts;
    control.removeAt(index);
  }

  /**
   * This method sets the accounts in the data object array
   */
  setAccounts() {
    let control = <FormArray>this.personaInfoForm.controls.accounts;
    this.data.accounts.forEach(x =>{
      control.push(this.formBuilder.group({
        domain: x.domain,
        username: x.username,
        password: x.password
      }))
    })
  }

  otherOption: Boolean = undefined;

  get(data) {
    this.isSelected = true;
    if (data.value == 'Other') {
      this.otherOption = true;
    } else {
      this.otherOption = false;
    }
  }

  convertResultToPersona(result: any): Persona {
    let persona: Persona = new Persona();
    let accounts: Account[] = [];
    if (result.accounts.length > 0) {
      for (let account of result.accounts) {
        let accountTemp: Account = new Account();
        accountTemp.domain = (account.domain ? account.domain : '');
        accountTemp.username = (account.username ? account.username : '');
        accountTemp.notes = (account.notes ? account.notes : '');
        accountTemp.password = (account.password ? account.password : '');
        accounts.push(account);
      }
    }
    persona.id = this.id;
    persona.checkedOut = result.checkedOut;
    persona.college = result.college;
    persona.currentLocation = result.currentLoc;
    persona.dob = result.dob;
    persona.firstName = result.firstName;
    persona.gender = result.gender;
    persona.hobbies = result.hobbies;
    persona.hs = result.hs;
    persona.imei = result.imei;
    persona.lastName = result.lastName;
    persona.mailingAddress = result.mailingAdd;
    persona.modelNumber = result.modelNum;
    persona.name = result.name;
    persona.phone = result.phone;
    persona.phoneNetwork = result.phoneNetwork;
    persona.pin = result.pin;
    persona.pob = result.pob;
    persona.region = result.regions;
    persona.serialNumber = result.serialNum;
    persona.type = result.type;
    persona.user = result.user;
    persona.work = result.work;
    persona.accounts = accounts;
    persona.profilePicPath = this.persona.profilePicPath;

    return persona;
  }
}
