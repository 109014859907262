export class Log {
    id: number;
    personaId: number;
    dateTime: Date;
    name: string;
    type: string;
    user: string;
    media: string;
    title: string;
    text: string;
    created: Date;
    edited: Date;
}
