export class Note {
    id: number;
    personaId: number;
    title: string;
    content: string;
    created: Date;
    edited: Date;
    saved: boolean = true;
    
    constructor() {
        
    }
}
