import * as CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { Account  } from '../objects/account';
import { Alert } from '../objects/alert';
import { Log } from '../objects/log';
import { Note } from '../objects/note';
import { Persona } from '../objects/persona';
import { Resource } from '../objects/resource';
import { UserLoginLog } from '../objects/userLoginLog'

interface LogCount {
  name: string,
  count: number
}

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  private INCEPTION_DB_API = 'InceptionDatabaseApi';
  private DB_PASSWORD_ENCRYPT_1 = 'DFK34KN314LN';
  private DB_PASSWORD_ENCRYPT_2 = 'LK5M26LK2444';
  private DB_PASSWORD_ENCRYPT_3 = '5K6N5IV9B45K';
  private DB_PASSWORD_ENCRYPTS: string[] = ['DFK34KN314LN', 'LK5M26LK2444', '5K6N5IV9B45K'];
  
  constructor(private amplifyService: AmplifyService) {}

  // GETs ***********************************************************************************************************************
  async getAllPersonas(): Promise<Persona[]> {
    let personas: Persona[] = [];
    const path = '/retrieve/allAvatarInformation';
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data) {
      for (let data of dbResponse.data) 
        personas.push(this.databaseObjectToPersona(data));
      }
    return personas;
  }

  async getAllAccounts(): Promise<Account[]> {
    let accounts: Account[] = [];
    const path = '/retrieve/allAccounts';
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data) {
      for (let data of dbResponse.data) 
        accounts.push(this.databaseObjectToAccount(data));
    }
    return accounts;
  }

  async getAccountsByRegion(region: string): Promise<Account[]> {
    let accounts: Account[] = [];
    const path = `/retrieve/accountsByRegion/${region}`;
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data) {
      for (let data of dbResponse.data) 
        accounts.push(this.databaseObjectToAccount(data));
    }
    return accounts;
  }

  async getAllNotes(): Promise<Note[]> {
    let notes: Note[] = [];
    const path = '/retrieve/allNotes';
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data) {
      for (let data of dbResponse.data) 
        notes.push(this.databaseObjectToNote(data));
    }
    return notes;
  }

  async getAllLogs(): Promise<Log[]> {
    let logs: Log[] = [];
    const path = '/retrieve/allLogs';
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data) {
      for (let data of dbResponse.data) 
        logs.push(this.databaseObjectToLog(data));
    }
    return logs;
  }

  async getLogsByRegion(region: string): Promise<Log[]> {
    let logs: Log[] = [];
    const path = `/retrieve/logsByRegion/${region}`;
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data) {
      for (let data of dbResponse.data) 
        logs.push(this.databaseObjectToLog(data));
    }
    return logs;
  }

  async getPersonasByRegion(region: string): Promise<Persona[]> {
    let personas: Persona[] = [];
    const path = `/retrieve/avatarInformationByRegion/${region}`;
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data) {
      for (let data of dbResponse.data) 
        personas.push(this.databaseObjectToPersona(data));
    }
    return personas;
  }

  async getPersonasByUser(user: string): Promise<Persona[]> {
    let personas: Persona[] = [];
    const path = `/retrieve/avatarInformationByUser/${user}`;
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data) {
      for (let data of dbResponse.data) 
        personas.push(this.databaseObjectToPersona(data));
    }
    return personas;
  }

  async getAlertsByUsername(user: string): Promise<Alert[]> {
    let alerts: Alert[] = [];
    const path = `/retrieve/alertsByUser/${user}`;
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data) {
      for (let data of dbResponse.data) 
        alerts.push(this.databaseObjectToAlerts(data));
    }
    return alerts;
  }

  async getPersonaById(id: number): Promise<Persona> {
    let persona: Persona = new Persona();
    const path = `/retrieve/avatarInformationById/${id}`;
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data[0]) {
      persona = this.databaseObjectToPersona(dbResponse.data[0]);
    }
    return persona;
  }

  async getAccountById(id: number): Promise<Account> {
    let account: Account = new Account();
    const path = `/retrieve/accountById/${id}`;
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data[0]) {
      account = this.databaseObjectToAccount(dbResponse.data[0]);
    }
    return account;
  }

  async getNoteById(id: number): Promise<Note> {
    let note: Note = new Note();
    const path = `/retrieve/noteById/${id}`;
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data[0]) {
      note = this.databaseObjectToNote(dbResponse.data[0]);
    }
    return note;
  }

  async getLogById(id: number): Promise<Log> {
    let log: Log = new Log();
    const path = `/retrieve/logById/${id}`;
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data[0]) {
      log = this.databaseObjectToLog(dbResponse.data[0]);
    }
    return log;
  }

  async getAccountsByPersonaId(persona_id: number): Promise<Account[]> {
    let accounts: Account[] = [];
    const path = `/retrieve/accountsByAvatarId/${persona_id}`;
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data) {
      for (let dbAccount of dbResponse.data) {
        accounts.push(this.databaseObjectToAccount(dbAccount));
      }
    }
    return accounts;
  }

  async getNotesByPersonaId(persona_id: number): Promise<Note[]> {
    let notes: Note[] = [];
    const path = `/retrieve/notesByAvatarId/${persona_id}`;
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data) {
      for (let dbNote of dbResponse.data) {
        notes.push(this.databaseObjectToNote(dbNote));
      }
    }
    return notes;
  }

  async getLogsByPersonaId(persona_id: number): Promise<Log[]> {
    let logs: Log[] = [];
    const path = `/retrieve/logsByAvatarId/${persona_id}`;
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data) {
      for (let dbLog of dbResponse.data) {
        logs.push(this.databaseObjectToLog(dbLog));
      }
    }
    return logs;
  }
  
  async getRegions(): Promise<string[]> {
    let regions: string[] = [];
    const path = '/retrieve/regions';
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data.length > 0) {
      for (let i of dbResponse.data) {
        regions.push(i.region);
      }
    }
    return regions;
  }

  async getLogCounts(): Promise<LogCount[]> {
    let counts: LogCount[] = [];
    const path = '/retrieve/logCount';
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data.length > 0) {
      for (let i of dbResponse.data) {
        let lc: LogCount = { name: i.name, count: i.count };
        counts.push(lc);
      }
    }
    return counts;
  }

  async getLogCountsByRegion(region: string): Promise<LogCount[]> {
    let counts: LogCount[] = [];
    const path = `/retrieve/logCountByRegion/${region}`;
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data.length > 0) {
      for (let i of dbResponse.data) {
        let lc: LogCount = { name: i.name, count: i.count };
        counts.push(lc);
      }
    }
    return counts;
  }

  async getResources(): Promise<Resource[]> {
    let resources: Resource[] = [];
    const path = `/retrieve/resources`;
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data.length > 0) {
      for (let i of dbResponse.data) {
        resources.push(i);
      }
    }
    return resources;
  }

  async getUserLoginLogs(): Promise<UserLoginLog[]> {
    let userLoginLogs: UserLoginLog[] = [];
    const path = `/retrieve/userLoginLogs`;
    const dbResponse = await this.amplifyService.api().get(this.INCEPTION_DB_API, path);
    if (dbResponse.data.length > 0) {
      for (let i of dbResponse.data) {
        userLoginLogs.push(i);
      }
    }
    return userLoginLogs;
  }

  // POSTs **********************************************************************************************************************
  // Create Persona 
  async createPersona(persona: Persona) {
    const createPath = '/create/avatar';
    const init = {
      body: {
        data: {
          college: (persona.college ? this.encryptString(persona.college) : ''),
          currentLoc: (persona.currentLocation ? this.encryptString(persona.currentLocation) : ''),
          dob: (persona.dob ? this.encryptDate(persona.dob) : null), //date
          gender: (persona.gender ? this.encryptString(persona.gender) : ''),
          hobbies: (persona.hobbies ? this.encryptString(persona.hobbies) : ''),
          hs: (persona.hs ? this.encryptString(persona.hs) : ''),
          imei: (persona.imei ? this.encryptString(persona.imei) : ''),
          mailingAdd: (persona.mailingAddress ? this.encryptString(persona.mailingAddress) : ''),
          modelNum: (persona.modelNumber ? this.encryptString(persona.modelNumber) : ''),
          name: (persona.name ? this.encryptString(persona.name) : ''),
          firstName: (persona.firstName ? this.encryptString(persona.firstName) : ''),
          lastName: (persona.lastName ? this.encryptString(persona.lastName) : ''),
          phone: (persona.phone ? this.encryptString(persona.phone) : ''),
          phoneNetwork: (persona.phoneNetwork ? this.encryptString(persona.phoneNetwork) : ''),
          pin: (persona.pin ? this.encryptString(persona.pin) : ''),
          pob: (persona.pob ? this.encryptString(persona.pob) : ''),
          region: (persona.region ? persona.region : ''),
          serialNum: (persona.serialNumber ? this.encryptString(persona.serialNumber) : ''),
          type: (persona.type ? this.encryptString(persona.type) : ''),
          user: (persona.user ? this.encryptString(persona.user) : ''),
          work: (persona.work ? this.encryptString(persona.work) : ''),
          profilePicPath: (persona.profilePicPath ? this.encryptString(persona.profilePicPath) : ''),
          created: new Date(),
          edited: new Date()
        }
      }
    };

    return await this.amplifyService.api().post(this.INCEPTION_DB_API, createPath, init);
  }

  // Create Account entry
  async createAccount(accountData: Account): Promise<any> {
    const createPath = '/create/account';
    const init = {
      body: {
        data: {
          personaId: (accountData.personaId ? accountData.personaId : 0),
          domain: (accountData.domain ? this.encryptString(accountData.domain) : ''),
          notes: (accountData.notes ? this.encryptString(accountData.notes) : ''),
          password: (accountData.password ? this.encryptString(accountData.password) : ''),
          username: (accountData.username ? this.encryptString(accountData.username) : ''),
          created: new Date(),
          edited: new Date()
        }
      }
    };
    return await this.amplifyService.api().post(this.INCEPTION_DB_API, createPath, init);
  }

  // Create Alert entry
  async createAlert(alertData: Alert): Promise<any> {
    const createPath = '/create/alert';
    const init = {
      body: {
        data: {
          username: (alertData.username ? alertData.username : ''),
          title: (alertData.title ? alertData.title : ''),
          body: (alertData.body ? alertData.body : ''),
          created: new Date(),
          edited: new Date()
        }
      }
    };
    return await this.amplifyService.api().post(this.INCEPTION_DB_API, createPath, init);
  }

  // Create Note entry
  async createNote(noteData: Note): Promise<any> {
    const createPath = '/create/note';
    const init = {
      body: {
        data: {
          personaId: (noteData.personaId ? noteData.personaId : 0),
          title: (noteData.title ? this.encryptString(noteData.title) : ''),
          content: (noteData.content ? this.encryptString(noteData.content) : ''),
          created: new Date(),
          edited: new Date()
        }
      }
    };
    return await this.amplifyService.api().post(this.INCEPTION_DB_API, createPath, init);
  }

  // Create Log entry
  async createLog(log: Log): Promise<any> {
    const createPath = '/create/log';
    const init = {
      body: {
        data: {
          dateTime: (log.dateTime ? log.dateTime : new Date()),
          personaId: (log.personaId ? log.personaId : 0),
          type: (log.type ? log.type : ''),
          user: (log.user ? this.encryptString(log.user) : ''),
          media: (log.media ? this.encryptString(log.media) : ''),
          title: (log.title ? this.encryptString(log.title) : ''),
          text: (log.text ? this.encryptString(log.text) : ''),
          created: new Date(),
          edited: new Date()
        }
      }
    };
    return await this.amplifyService.api().post(this.INCEPTION_DB_API, createPath, init);
  }
  
  // Create Log entry
  async createResource(resource: Resource): Promise<any> {
    const createPath = '/create/resource';
    const init = {
      body: {
        data: {
          name: (resource.name ? resource.name : ''),
          text: (resource.text ? resource.text : ''),
          created: new Date(),
          edited: new Date()
        }
      }
    };
    return await this.amplifyService.api().post(this.INCEPTION_DB_API, createPath, init);
  }

    // Create User Login
    async createUserLoginLog(userLoginLog: UserLoginLog): Promise<any> {
      const createPath = '/create/userLoginlog';
      const init = {
        body: {
          data: {
            username: (userLoginLog.username ? userLoginLog.username : ''),
            action: (userLoginLog.action ? userLoginLog.action : '')
          }
        }
      };
      return await this.amplifyService.api().post(this.INCEPTION_DB_API, createPath, init);
    }

  

  
  // PUTs ***********************************************************************************************************************
  async updateCheckoutStatus(checkedOut: boolean, personaId: number): Promise<any> {
    const updatePath = '/update/checkoutStatus';
    const init = {
      body: {
        data: {
          id: personaId,
          checkedOut: (checkedOut ? 1 : 0),
          edited: new Date()
        }
      }
    };
    return await this.amplifyService.api().put(this.INCEPTION_DB_API, updatePath, init);
  }

  async updatePersona(persona: Persona): Promise<any> {
    persona.college = (persona.college ? this.encryptString(persona.college) : ''),
    persona.currentLocation = (persona.currentLocation ? this.encryptString(persona.currentLocation) : ''),
    persona.dob = (persona.dob ? this.encryptDate(persona.dob) : null), //date
    persona.gender = (persona.gender ? this.encryptString(persona.gender) : ''),
    persona.hobbies = (persona.hobbies ? this.encryptString(persona.hobbies) : ''),
    persona.hs = (persona.hs ? this.encryptString(persona.hs) : ''),
    persona.imei = (persona.imei ? this.encryptString(persona.imei) : ''),
    persona.mailingAddress = (persona.mailingAddress ? this.encryptString(persona.mailingAddress) : ''),
    persona.modelNumber = (persona.modelNumber ? this.encryptString(persona.modelNumber) : ''),
    persona.name = (persona.name ? this.encryptString(persona.name) : ''),
    persona.firstName = (persona.firstName ? this.encryptString(persona.firstName) : ''),
    persona.lastName = (persona.lastName ? this.encryptString(persona.lastName) : ''),
    persona.phone = (persona.phone ? this.encryptString(persona.phone) : ''),
    persona.phoneNetwork = (persona.phoneNetwork ? this.encryptString(persona.phoneNetwork) : ''),
    persona.pin = (persona.pin ? this.encryptString(persona.pin) : ''),
    persona.pob = (persona.pob ? this.encryptString(persona.pob) : ''),
    persona.region = (persona.region ? persona.region : ''),
    persona.serialNumber = (persona.serialNumber ? this.encryptString(persona.serialNumber) : ''),
    persona.type = (persona.type ? this.encryptString(persona.type) : ''),
    persona.user = (persona.user ? this.encryptString(persona.user) : ''),
    persona.work = (persona.work ? this.encryptString(persona.work) : ''),
    persona.profilePicPath = (persona.profilePicPath ? this.encryptString(persona.profilePicPath) : ''),
    persona.edited = new Date()
    const updatePath = '/update/avatar';
    const init = {
      body: { persona: persona }
    }
    return await this.amplifyService.api().put(this.INCEPTION_DB_API, updatePath, init);
  }

  async updateAccount(account: Account): Promise<any> {
    account.domain = this.encryptString(account.domain);
    account.notes = this.encryptString(account.notes);
    account.password = this.encryptString(account.password);
    account.username = this.encryptString(account.username);
    account.edited = new Date();
    const updatePath = '/update/account';
    const init = {
      body: { account: account }
    };
    return await this.amplifyService.api().put(this.INCEPTION_DB_API, updatePath, init);
  }

  async updateNote(note: Note): Promise<any> {
    note.title = this.encryptString(note.title);
    note.content = this.encryptString(note.content);
    note.edited = new Date();
    const updatePath = '/update/note';
    const init = {
      body: { note: note }
    };
    return await this.amplifyService.api().put(this.INCEPTION_DB_API, updatePath, init);
  }

  async updateLog(log: Log): Promise<any> {
    log.user = this.encryptString(log.user);
    log.media = this.encryptString(log.media);
    log.title = this.encryptString(log.title);
    log.text = this.encryptString(log.text);
    log.edited = new Date();
    const updatePath = '/update/log';
    const init = {
      body: { log: log }
    };
    return await this.amplifyService.api().put(this.INCEPTION_DB_API, updatePath, init);
  }

  async readAlert(alertId: number): Promise<any> {
    const updatePath = '/update/readAlert';
    const init = {
      body: { 
        id: alertId,
        edited: new Date()
      }
    };
    return await this.amplifyService.api().put(this.INCEPTION_DB_API, updatePath, init);
  }

  async updateResource(resource: Resource): Promise<any> {
    resource.name = (resource.name);
    resource.text = (resource.text);
    resource.id = (resource.id)
    resource.edited = new Date();
    const updatePath = '/update/resource';
    const init = {
      body: { resource: resource }
    };
    return await this.amplifyService.api().put(this.INCEPTION_DB_API, updatePath, init);
  }

  // DELETEs ********************************************************************************************************************
  async removePersona(id: number): Promise<any> {
    let deleted: boolean = false;
    const deletePath = `/remove/avatar/${id}`;
    const init = {};
    const response = await this.amplifyService.api().del(this.INCEPTION_DB_API, deletePath, init);
    if (response) {
      if (!response.error) 
        deleted = true;
    }
    return deleted;
  }

  async removeAccount(id: number): Promise<any> {
    let deleted: boolean = false;
    const deletePath = `/remove/account/${id}`;
    const init = {};
    const response = await this.amplifyService.api().del(this.INCEPTION_DB_API, deletePath, init);
    if (response) {
      if (!response.error) 
        deleted = true;
    }
    return deleted;
  }

  async removeAccountsByPersona(personaId: number): Promise<any> {
    let deleted: boolean = false;
    const deletePath = `/remove/accountByAvatar/${personaId}`;
    const init = {};
    const response = await this.amplifyService.api().del(this.INCEPTION_DB_API, deletePath, init);
    if (response) {
      if (!response.error) 
        deleted = true;
    }
    return deleted;
  }

  async removeNote(id: number): Promise<any> {
    let deleted: boolean = false;
    const deletePath = `/remove/note/${id}`;
    const init = {};
    const response = await this.amplifyService.api().del(this.INCEPTION_DB_API, deletePath, init);
    if (response) {
      if (!response.error) 
        deleted = true;
    }
    return deleted;
  }

  async removeNotesByPersona(personaId: number): Promise<any> {
    let deleted: boolean = false;
    const deletePath = `/remove/notesByAvatar/${personaId}`;
    const init = {};
    const response = await this.amplifyService.api().del(this.INCEPTION_DB_API, deletePath, init);
    if (response) {
      if (!response.error) 
        deleted = true;
    }
    return deleted;
  }

  async removeLog(id: number): Promise<boolean> {
    let deleted: boolean = false;
    const deletePath = `/remove/log/${id}`;
    const init = {};
    const response = await this.amplifyService.api().del(this.INCEPTION_DB_API, deletePath, init);
    if (response) {
      if (!response.error) 
        deleted = true;
    }
    return deleted;
  }

  async removeLogsByPersona(personaId: number): Promise<any> {
    let deleted: boolean = false;
    const deletePath = `/remove/logsByAvatar/${personaId}`;
    const init = {};
    const response = await this.amplifyService.api().del(this.INCEPTION_DB_API, deletePath, init);
    if (response) {
      if (!response.error) 
        deleted = true;
    }
    return deleted;
  }


  //still need to finish this one
  async removeResource(name: string): Promise<any> {
    let deleted: boolean = false;
    const deletePath = `/remove/resource/${name}`;
    const init = {};
    const response = await this.amplifyService.api().del(this.INCEPTION_DB_API, deletePath, init);
    if (response) {
      if (!response.error) 
        deleted = true;
    }
    return deleted;

  }

  

  // Helper functions ***********************************************************************************************************
  databaseObjectToPersona(dbResp: any): Persona {
    let persona: Persona = new Persona();
    persona.id = (dbResp.id ? dbResp.id : null);
    persona.name = (dbResp.name ? this.decryptString(dbResp.name) : null);
    persona.firstName = (dbResp.first_name ? this.decryptString(dbResp.first_name) : null);
    persona.lastName = (dbResp.last_name ? this.decryptString(dbResp.last_name) : null);
    persona.user = (dbResp.user ? this.decryptString(dbResp.user) : null);
    persona.checkedOut = ((dbResp.checked_out === 1) ? true : false);
    persona.college = (dbResp.college ? this.decryptString(dbResp.college) : null);
    persona.currentLocation = (dbResp.current_loc ? this.decryptString(dbResp.current_loc) : null);
    persona.dob = (dbResp.dob ? this.decryptDate(dbResp.dob) : null);
    persona.gender = (dbResp.gender ? this.decryptString(dbResp.gender) : null);
    persona.hobbies = (dbResp.hobbies ? this.decryptString(dbResp.hobbies) : null);
    persona.hs = (dbResp.hs ? this.decryptString(dbResp.hs) : null);
    persona.imei = (dbResp.imei ? this.decryptString(dbResp.imei) : null);
    persona.mailingAddress = (dbResp.mailing_address ? this.decryptString(dbResp.mailing_address) : null);
    persona.modelNumber = (dbResp.model_number ? this.decryptString(dbResp.model_number) : null);
    persona.phone = (dbResp.phone ? this.decryptString(dbResp.phone) : null);
    persona.phoneNetwork = (dbResp.phone_network ? this.decryptString(dbResp.phone_network) : null);
    persona.pin = (dbResp.pin ? this.decryptString(dbResp.pin) : null);
    persona.pob = (dbResp.pob ? this.decryptString(dbResp.pob) : null);
    persona.region = (dbResp.region ? dbResp.region : null);
    persona.serialNumber = (dbResp.serial_number ? this.decryptString(dbResp.serial_number) : null);
    persona.type = (dbResp.type ? this.decryptString(dbResp.type) : null);
    persona.work = (dbResp.work ? this.decryptString(dbResp.work) : null);
    persona.accountCount = (dbResp.accountCount ? dbResp.accountCount : 0);
    persona.created = (dbResp.created ? new Date(dbResp.created) : null);
    persona.edited = (dbResp.edited ? new Date(dbResp.edited) : null);
    persona.profilePicPath = (this.decryptString(dbResp.profile_pic_path));

    return persona
  }

  // Convert DB account to Account object
  databaseObjectToAccount(dbAccount: any): Account {
    let account: Account = new Account();
    account.id = (dbAccount.id ? dbAccount.id : 0);
    account.personaId = (dbAccount.persona_id ? dbAccount.persona_id : 0);
    account.domain = (dbAccount.domain ? this.decryptString(dbAccount.domain) : '');
    account.username = (dbAccount.username ? this.decryptString(dbAccount.username) : '');
    account.notes = (dbAccount.notes ? this.decryptString(dbAccount.notes) : '');
    account.password = (dbAccount.password ? this.decryptString(dbAccount.password) : '');
    account.created = (dbAccount.created ? new Date(dbAccount.created) : null);
    account.edited = (dbAccount.edited ? new Date(dbAccount.edited) : null);
    return account;
  }

  databaseObjectToAlerts(dbAlert: any): Alert {
    let alert: Alert = new Alert();
    alert.id = (dbAlert.id ? dbAlert.id : 0);
    alert.username = (dbAlert.username ? dbAlert.username : '');
    alert.title = (dbAlert.title ? dbAlert.title : '');
    alert.body = (dbAlert.body ? dbAlert.body : '');
    if (dbAlert.unread) {
      if (dbAlert.unread === 1) {
        alert.unread = true;
      } else {
        alert.unread = false;
      }
    }
    alert.created = (dbAlert.created ? new Date(dbAlert.created) : null);
    alert.edited = (dbAlert.edited ? new Date(dbAlert.edited) : null);
    return alert;
  }

  // Convert DB note to Note object
  databaseObjectToNote(dbNote: any): Note {
    let note: Note = new Note();
    note.id = (dbNote.id ? dbNote.id : 0);
    note.personaId = (dbNote.persona_id ? dbNote.persona_id : 0);
    note.title = (dbNote.title ? this.decryptString(dbNote.title) : '');
    note.content = (dbNote.content ? this.decryptString(dbNote.content) : '');
    note.created = (dbNote.created ? new Date(dbNote.created) : null);
    note.edited = (dbNote.edited ? new Date(dbNote.edited) : null);
    return note;
  }

  // Convert DB log to Log object
  databaseObjectToLog(dbLog: any): Log {
    let log: Log = new Log();
    log.id = (dbLog.id ? dbLog.id : 0);
    log.personaId = (dbLog.persona_id ? dbLog.persona_id : 0);
    log.dateTime = (dbLog.date_time ? new Date(dbLog.date_time) : null);
    log.name = (dbLog.name ? this.decryptString(dbLog.name) : '');
    log.type = (dbLog.type ? dbLog.type : '');
    log.user = (dbLog.user ? this.decryptString(dbLog.user) : '');
    log.media = (dbLog.media ? this.decryptString(dbLog.media) : '');
    log.title = (dbLog.title ? this.decryptString(dbLog.title) : '');
    log.text = (dbLog.text ? this.decryptString(dbLog.text) : '');
    log.created = (dbLog.created ? new Date(dbLog.created) : null);
    log.edited = (dbLog.edited ? new Date(dbLog.edited) : null);
    return log;
  }  

  // Encrypt Password
  encryptString(password: string): string {
    let key: any;
    for (let pw of this.DB_PASSWORD_ENCRYPTS) {
      key = CryptoJS.enc.Utf8.parse(pw);
      password = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password.toString()), key, {
        keySize: 128 / 8,
        iv: key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    }
    
    return password.toString();
  }

  // Decrypt Password
  decryptString(password: any): string {
    if(password) {
      let key: any;
      for (let i = this.DB_PASSWORD_ENCRYPTS.length - 1; i >= 0; i--) {
        try{
        key = CryptoJS.enc.Utf8.parse(this.DB_PASSWORD_ENCRYPTS[i]);
        if (i === this.DB_PASSWORD_ENCRYPTS.length - 1) {
          password = CryptoJS.AES.decrypt(password, key, {
            keySize: 128 / 8,
            iv: key,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          });
        } else {
          password = CryptoJS.AES.decrypt(password.toString(CryptoJS.enc.Utf8), key, {
            keySize: 128 / 8,
            iv: key,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          });
        }
      }
      catch(error){

      }
    }
    return password.toString(CryptoJS.enc.Utf8);
    }
  }

  encryptDate(password: Date): string {
    let key: any;
    let pass = password.toString();
    for (let pw of this.DB_PASSWORD_ENCRYPTS) {
      key = CryptoJS.enc.Utf8.parse(pw);
      pass = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(pass.toString()), key, {
        keySize: 128 / 8,
        iv: key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    }
    
    return pass.toString();
  
  }

  decryptDate(password: any): Date {
    let key: any;
    let date: any;
    for (let i = this.DB_PASSWORD_ENCRYPTS.length - 1; i >= 0; i--) {
      try{
      key = CryptoJS.enc.Utf8.parse(this.DB_PASSWORD_ENCRYPTS[i]);
      if (i === this.DB_PASSWORD_ENCRYPTS.length - 1) {
        password = CryptoJS.AES.decrypt(password, key, {
          keySize: 128 / 8,
          iv: key,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });
      } else {
        password = CryptoJS.AES.decrypt(password.toString(CryptoJS.enc.Utf8), key, {
          keySize: 128 / 8,
          iv: key,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });
      }
      date = new Date(password.toString(CryptoJS.enc.Utf8));
      console.log(date.toLocaleDateString('en-US'));
      }
      catch(error){
        console.log(error)
      }
    }    
    return new Date(date.toLocaleDateString('en-US'));
  }
}
