import { AssignedNumber } from './assigned-number';

export class User {
    id: string;
    email: string;
    phone_number: string;
    username: string;
    given_name: string;
    family_name: string;
    token: string;
    groups: string[];
    isAdmin: boolean = false;
    cognitoUser: any;
    last_login: string;
    create_date: string;
    enabled: boolean;
    status: string;
    assignedNumbers: AssignedNumber[];
}
