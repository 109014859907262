import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private subject: BehaviorSubject<any>;
  private previousMessage: any ;
  
  constructor() {
    this.previousMessage = JSON.parse(sessionStorage.getItem('previousMessage'));
    this.subject = new BehaviorSubject<any>(this.previousMessage);
  }

  sendMessage(message: string) {
    if (this.previousMessage) {
      if (this.previousMessage.text !== message) {
        this.previousMessage.text = message;
        sessionStorage.setItem('previousMessage', JSON.stringify(this.previousMessage));
        this.subject.next(this.previousMessage);
      }
    } else {
      this.previousMessage = { text: message };
      sessionStorage.setItem('previousMessage', JSON.stringify(this.previousMessage));
      this.subject.next(this.previousMessage);
    }
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
