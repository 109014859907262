export class Account {
    id: number;
    personaId: number;
    domain: string;
    notes: string;
    password: string;
    username: string;
    created: Date;
    edited: Date;

    constructor() {
        
    }
}
