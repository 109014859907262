import { Component, OnDestroy, OnInit, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../helpers/services";
import { Storage } from 'aws-amplify';
import * as jspdf from "jspdf";
import html2canvas from "html2canvas";
import { MatTableDataSource } from "@angular/material";
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/';
import _ from 'lodash';
import { Page } from "../helpers/objects/page";
import { DatabaseService } from '../helpers/services/database.service';
import { Persona } from '../helpers/objects/persona';
import { Account } from "../helpers/objects/account";
import { Note } from "../helpers/objects/note";
import { Log } from '../helpers/objects/log';
import { ResourceGroups } from 'aws-sdk';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';


export interface AccountsDialogData {
  domain: string;
  username: string;
  password: string;
  options:['Gmail', 'Outlook', 'Facebook','LinkedIn', 'Twitter', 'Instagram',
  'Buffer', 'Yahoo', 'Pinterest', 'Wickr', 'Whatsapp', 'AOL', 'About.Me',
  'Canva', 'iCloud', 'Blur'];
  action: string;
}

export interface NotesDialogData {
}

export interface SessionsDialogData {
}

export interface ViewDialogData{
}

export class LogElement{
  title: string;
}

@Component({
  selector: 'app-persona-page',
  templateUrl: './persona-page.component.html',
  styleUrls: ['./persona-page.component.scss']
})

@NgModule({
  imports: [
    MatTooltipModule,
  ],
})

export class PersonaPageComponent implements OnInit, OnDestroy {
  PERSONA_API = 'chameleonDBAPI';
  accounts: Account[] = [];
  public name: string;
  public id: number;
  public username = this.authenticationService.currentUserValue.username;
  public personaInfo: Persona = null;
  public group = this.authenticationService.currentUserValue.groups;
  private sub: any;
  decryptedContent: string = 'Loading...';

  // Table
  dataSource: any;
  accountColumns: string[] = ['domain', 'username', 'password', 'notes', 'editAccount', 'deleteAccount'];
  fileLogsColumns: string[] = ['checkbox', 'title', 'uploader', 'date', 'time', 'type', 'media', 'log'];
  allDataFetched: boolean = false;

  // Notes
  titleModel: string;
  contentModel: string;
  notes: Note[] = [];
  newNote: string[] = [];
  newTitle: string[] = [];
  timer: any;
  noteContentControls: { [key: number]: FormControl } = {};
  
  //session logs
  logs: Log[] = [];
  file: any[];
  checkBoxSelected: number = -1;
  lastCheckBoxSelected: number = -1;
  checkBoxIsSelected: boolean = false;
  logToEdit;

  pages: Page[] = [];

  selected = new FormControl(0);
  fileLog: boolean = false;

  myControl = new FormControl();
  options:['Gmail', 'Outlook', 'Facebook','LinkedIn', 'Twitter', 'Instagram',
        'Buffer', 'Yahoo', 'Pinterest', 'Wickr', 'Whatsapp', 'AOL', 'About.Me',
        'Canva', 'iCloud', 'Blur'];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              public databaseService: DatabaseService) {
    if (this.router.getCurrentNavigation().extras == 'logs') {
      this.fileLog = true;
    }

    // Making the fields empty
    this.titleModel = '';
    this.contentModel = '';

    this.sub = this.route.params.subscribe(params => {
      this.name = params.persona_name;
      this.id = params.id;
    });

    this.getPersonInfo().then(result => {
      this.initProfilePic();
    }).then(result => {
      this.loadLogs();
    }).then(result => {
      this.loadAccounts();
    }).then(result => {
      this.allDataFetched = true;
      
      if (this.fileLog)
        this.selected.setValue(1);
    }).catch(err => console.log('could not initialize person'));

    this.loadNotes();
  }

  ngOnInit() {
    let infoPage: Page = { icon: 'info', title: 'Information', pageLabel: 'information-page', selected: true };
    let accountPage: Page = { icon: 'account_circle', title: 'Accounts', pageLabel: 'accounts-page', selected: false };
    let notesPage: Page = { icon: 'note', title: 'Notes', pageLabel: 'notes-page', selected: false };
    let filesLogsPage: Page = { icon: 'text_snippet', title: 'Session Logs', pageLabel: 'filesLogs-page', selected: false };

    if (this.fileLog) {
      infoPage = { icon: 'info', title: 'Information', pageLabel: 'information-page', selected: false };
      filesLogsPage = { icon: 'text_snippet', title: 'Session Logs', pageLabel: 'filesLogs-page', selected: true };
    }
    
    this.pages = [infoPage, accountPage, notesPage, filesLogsPage];
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  downloadFile(url):void {
    Storage.get(url).then(result => this.createDownload(String(result)));
  }

  private createDownload(url) {
    window.open(url, '_blank');
  } 
  
  request(method, url) {
    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open(method, url);
        xhr.onload = resolve;
        xhr.onerror = reject;
        xhr.onreadystatechange = function() {
          console.log(xhr.responseText);
        }
        xhr.send();
    });
}

  onMatBoxChange(index, theLog){    
    this.checkBoxSelected = index;
    if(this.lastCheckBoxSelected === this.checkBoxSelected){
      this.checkBoxIsSelected ? this.checkBoxIsSelected = false : this.checkBoxIsSelected = true;
    } else {
        this.checkBoxIsSelected = true;
    }
    this.lastCheckBoxSelected = this.checkBoxSelected;
    this.logToEdit = theLog;
    console.log(theLog);
  }

  displayInformation(pageLabel: string) {
    for (let page of this.pages) {
      page.selected = (page.pageLabel === pageLabel) ?  true : false;
    }
  }

  clickProfilePic() {
    document.getElementById('pic-upload').click();
  }

  uploadProfilePic(pic) {
    if (pic) {
      Storage.put(this.personaInfo.profilePicPath, pic.files[0]).then(result => {
        document.getElementById("persona-picture").remove();
        this.initProfilePic();
      }).catch(err => console.log(err));
    }
  }

  formatPhoneNumber(phoneNumber: string): string {
    let areaCode: string = '';
    let subscriber: string = '';
    let returnNumber: string = '';
    if (phoneNumber.length >= 10) {
      if (phoneNumber.length === 10) {
        areaCode = phoneNumber.substring(0, 3);
      } else {
        areaCode = phoneNumber.substring(0, phoneNumber.length - 7);
        areaCode = '+' + areaCode.substring(0, areaCode.length - 3) + ' ' + areaCode.substring(areaCode.length -3, areaCode.length);
      }
      subscriber = phoneNumber.substring(phoneNumber.length - 7, phoneNumber.length);
      subscriber = subscriber.substring(0, 3) + '-' + subscriber.substring(3, subscriber.length);
      
      returnNumber = areaCode + '-' + subscriber;
    }
    return returnNumber;
  }

  initProfilePic() {
    let folderName: string = '';
    if (this.personaInfo.profilePicPath) {
      folderName = this.personaInfo.profilePicPath.replace('/profilepic.png', '');
    }
    let hasProfilePic: boolean = false;
    Storage.list(folderName).then(result => {
      if (folderName) {
        for (let entry of result) {
          if (String(entry.key).includes('profilepic.png')) {
            hasProfilePic = true;
          }
        }
      }
    }).then(result => {
      // If there is a valid picture stored in the S3, pull it and set it
      let image = document.createElement("img");
      let imageParent = document.getElementById("pic-container");
      image.id = "persona-picture";
      image.style.width = '100%';
      image.onmouseover = function() {
        image.style.opacity = '.75'
      };
      image.onmouseout = function() {
        image.style.opacity = '1'
      };
      
      if (hasProfilePic) {
        Storage.get(this.personaInfo.profilePicPath).then(result => {
          // If there is a valid picture stored in the S3, pull it and set it
          image.src = String(result);
          imageParent.appendChild(image);
        });
      } else {
        Storage.get('default_profile_pic.png').then(result => {
          image.src = String(result);
          imageParent.appendChild(image);
        });
      }
    }).catch(err => console.log(err));
  }

  rowAction(action, row) {
    if (action === 'edit') {
      this.openAccountsDialog(action, row);
    }
    if (action === 'delete') {
      let confirmDelete = confirm("Click ok to confirm deleting the selected account.");
      if (confirmDelete){
        this.deleteAccount(row);
      }
    }
  }
  
  openAccountsDialog(action = 'add', row = undefined): void {
    let theFormData = {};
    if (action === 'edit') {
      theFormData = {domain: row.domain, username: row.username, password: row.password, notes: row.notes, action: 'Edit'};
    }
    if (action === 'add') {
      theFormData = {domain: '', username: '', password: '', notes: '', action: 'Add'};
    }

    delete theFormData["domains"];
    const dialogRef = this.dialog.open(AppPersonaPageAccountsDialog, {
      data: theFormData
    });   

    dialogRef.afterClosed().subscribe(result => {
      if (result && action === 'edit'){
        this.editAccount(result, row);
      }
      if (result && action === 'add'){
        this.addAccount(result);
      }
    });
  }


  notesAction(action, row) {
    if (action === 'edit') {
      this.openNotesDialog(action, row);
    }
  }

  openNotesDialog(action = 'add', row = undefined): void {
    let theFormData = {};
    if (action === 'edit') {
      theFormData = {title: row.title, body: row.content, action: 'Edit'};
    }
    if (action === 'add') {
      theFormData = {title: '', body: '', action: 'Add'};
    }

    const dialogRef = this.dialog.open(AppPersonaPageNotesDialog, {
      data: theFormData
    });   

    dialogRef.afterClosed().subscribe(result => {
      if (result && action === 'edit'){
        this.editNote(result, row);
      }
      if (result && action === 'add'){
        this.addNote(result);
      }
    });


  }


  sessionsActions(action) {
    if (action === 'edit') {
      if (this.checkBoxIsSelected){
        console.log(`edit ${this.logToEdit.dateTime}`)
        this.openSessionsDialog(action);
      } else {
          alert('Please select a log to edit')
      }
    }
    if (action === 'delete') {
      if (this.checkBoxIsSelected){
        console.log(`delete ${this.logToEdit.dateTime}`)
        let confirmDelete = confirm("Click ok to confirm deleting the selected session log.");
        if (confirmDelete){
          this.deleteSession(this.logToEdit.id);
        }
      } else {
          alert('Please select a log to delete')
      }
    }
  }

  async openSessionsDialog(action = 'add'): Promise<void> {
    let theFormData = {};

    if (action === 'edit') {
      //ensure that the log is using the data from the database
      let response = await this.databaseService.getLogById(this.logToEdit.id);
      if (response){
        this.logToEdit = response;
        Storage.get(this.logToEdit.text).then(result => {
          let textToDisplay = String(result) 
          const self = this;
          this.request('GET', textToDisplay)
          .then(function (event: Event) {
            let theTextToDisplay = (event.target as XMLHttpRequest).response;
            theFormData = {title: self.logToEdit.title, media: self.logToEdit.media, text: theTextToDisplay, file: self.logToEdit.file};           
            const dialogRef = self.dialog.open(AppPersonaPageSessionsDialog, {
              data: theFormData
            });   
            dialogRef.afterClosed().subscribe(result => {
              if (result){
                self.logAction(result, action, self.logToEdit);
              }
            }); 
          }, 
          function (e) {
            console.log(e);
          });
        })
      }
    }

    if (action === 'add') {
      theFormData = {title: '', media: '', text: '', file: undefined };    
      const dialogRef = this.dialog.open(AppPersonaPageSessionsDialog, {
        data: theFormData
      });   

      dialogRef.afterClosed().subscribe(result => {

        if (result){
          this.logAction(result, action, this.logToEdit);
        }
      });
    }
  }

  openViewDialog(log){

    Storage.get(log.media).then(result => {
      log.image = String(result)
      Storage.get(log.text).then(result => {
        let textToDisplay = String(result)
        const self = this;
        this.request('GET', textToDisplay)
        .then(function (event: Event) {
          log.textToDisplay = (event.target as XMLHttpRequest).response;
          const dialogRef = self.dialog.open(AppPersonaPageViewDialog, {
            data: log
          })
          dialogRef.afterClosed().subscribe();
        }, function (e) {
          console.log(e);
        });
      })
    }).catch()
      
  }

  /**
   * This calls the database and gets the personas information
   * due to using formfields the form arrays are not able to get information for accounts and logs
  */ 
  async getPersonInfo(): Promise<Persona> {

    try {
      let response = await this.databaseService.getPersonaById(this.id);
      if (response) {
        this.personaInfo = response;
      }      
    } catch (error) {
      console.log("error");
    }
    return this.personaInfo;
  }


  /**
   * getting the accounts for the persona
   */
  async loadAccounts() {

    this.accounts = [];

    let response = await this.databaseService.getAccountsByPersonaId(this.id)
    if (response) {
      for (let entry of response) {
        this.accounts.push(entry);
      }
    }
    this.dataSource = new MatTableDataSource<Account>(this.accounts);
  }

  async addAccount(accountDataToAdd){
    try {
      let account: Account = new Account();
      account.domain = accountDataToAdd.domain;
      account.username = accountDataToAdd.username;
      account.password = accountDataToAdd.password;
      account.notes = accountDataToAdd.notes;
      account.personaId = this.id;

      let response = await this.databaseService.createAccount(account);
      if (response) {
        this.loadAccounts();  
      }      
    } catch (error) {
      console.log("error");
    }
  }  

  async editAccount(accountDataToAdd, currentAccount){
    try {
      let account: Account = new Account();
      account.domain = accountDataToAdd.domain;
      account.username = accountDataToAdd.username;
      account.password = accountDataToAdd.password;
      account.notes = accountDataToAdd.notes;
      account.personaId = this.id;
      account.created = currentAccount.created;
      account.id = currentAccount.id;
      let response = await this.databaseService.updateAccount(account);
      if (response) {
        this.loadAccounts();  
      }
    } catch (error) {
      console.log("error");
    }
  }  

  async deleteAccount(accountToDelete){
    try {
      let response = await this.databaseService.removeAccount(accountToDelete.id);
      if (response) {
        console.log(response)
        this.loadAccounts();  
      }
    } catch (error) {
      console.log("error");
    }
  }  

  async logAction(logToAdd, action, currentLog: Log){
    let mediaResponse = null;
    let logResponse = null;
    let date = new Date();
    try{
      let fileName: string = '';
      if (action === 'add') {
        fileName = this.name + '.' + date.getTime();
      }
      if (action === 'edit') {
        fileName = this.name + '.' + currentLog.dateTime.getTime();
      }
      
      // If there is a media attachment then put
      if (logToAdd.file) {
        let mediaFileType = String(logToAdd.file.type).split('/')[1];
        let fullFileName = fileName + '.' + mediaFileType;
        mediaResponse = await Storage.put(fullFileName, logToAdd.file);
      }
      // If there is a text log attachment then put
      if (logToAdd.text) {
        logResponse = await Storage.put(fileName + '.txt', logToAdd.text);
      }     
    } catch (error) {
      console.log("error");
    }

    if (action === 'add'){
      try {
        let log: Log = new Log();

        log.personaId = this.id;
        log.user = this.username;
        log.type = 'Session';
        log.title = logToAdd.title;
        if (mediaResponse) {
          log.media = mediaResponse['key'];
        };
        if (logResponse) {
          log.text = logResponse['key'];
        };

        let response = await this.databaseService.createLog(log);
        if (response) {
          this.loadLogs();
        }
      } catch (error) {
        console.log("error");
      }
    }

    if (action === 'edit') {
      try {
        let log: Log = currentLog;
        log.title = logToAdd.title;
        if (mediaResponse) {
          log.media = mediaResponse['key'];
        };
        if (logResponse) {
          log.text = logResponse['key'];
        };

        let response = await this.databaseService.updateLog(log);
        if (response) {
          this.loadLogs();
        }
      } catch (error) {
        console.log("error");
      }
    }
  }

  async deleteSession(id){

    try {
      let response = await this.databaseService.removeLog(id);
      if (response) {
        this.loadLogs();
      }
    } catch (error) {
      console.log("error");
    }

  }

  async loadNotes() {
    this.notes = [];
    let response = await this.databaseService.getNotesByPersonaId(this.id);
    if (response) {
      for (let entry of response) {
        this.notes.push(entry);
      }
    }
  }

  async loadLogs() {
    let response = await this.databaseService.getLogsByPersonaId(this.id)
    if (response) {
      this.logs = [];
      for (let entry of response) {
        entry.dateTime = new Date(entry.dateTime);
        this.logs.push(entry);
      }
    }
  }

  async checkInOrOut(stat, name) {
    try {

      let response = await this.databaseService.updateCheckoutStatus((this.personaInfo.checkedOut ? false : true), this.id)
      if (response) {
        this.getPersonInfo();
      }
    } catch (error) {
      console.log("error");
    }
  }

  // Sends to a different page of the persona requested for edit
  onEdit(selectedPerson: Persona) {
    this.router.navigate([`/avatars/edit/${selectedPerson.name}/${selectedPerson.id}`]);
  }

  //this method allows users to download a pdf of the personas information.
  public captureScreen() {
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      //var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      //var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(this.name.split('_')[1] + '.pdf'); // Generated PDF
    });
  }


  async addNote(noteDataToAdd) {
    let noteToAdd = new Note;
    noteToAdd.title = noteDataToAdd.title;
    noteToAdd.content = noteDataToAdd.body;
    noteToAdd.personaId = this.id;
    try {
      let response = await this.databaseService.createNote(noteToAdd);
      this.titleModel = this.contentModel = '';
      if (response) {
        let newNote: Note = await this.databaseService.getNoteById(response.data.insertId);
        if (newNote.id) {
          this.notes.push(newNote);
        }
      }
    } catch (error) {
      console.log("error");
    }
  }
  // Delete notes
  async deleteNote(note: Note) {
    let confirmedDelete: boolean = confirm(`Click "OK" to delete the note for ${note.title}`)
    if (confirmedDelete) {
      try {
        let response = await this.databaseService.removeNote(note.id);
        if (response) {
          let tempNotes: Note[] = [];
          for (let n of this.notes) {
            if (n.id !== note.id) {
              tempNotes.push(n)
            }
          }
          this.notes = tempNotes;
        }
      } catch (error) {
        console.log("error");
      }
    }
  }
  // Saving the edits in the already existing notes
  async editNote(noteDataToAdd, currentNote) {



    try {
      let note: Note = new Note();
      note.title = noteDataToAdd.title;
      note.content = noteDataToAdd.body;
      note.personaId = this.id;
      note.created = currentNote.created;
      note.id = currentNote.id;
      let response = await this.databaseService.updateNote(note);
      if (response) {
        this.loadNotes();  
      }
    } catch (error) {
      console.log("error");
    }
}
  
  async updateDecryptedContent(note: Note) {
    this.decryptedContent = 'Loading...'; // Set to an appropriate loading message or null
    this.decryptedContent = this.databaseService.decryptString(note.content);
  }
}

@Component({
  selector: 'app-persona-page-accounts-dialog',
  templateUrl: './persona-page-accounts-dialog.component.html'
})
export class AppPersonaPageAccountsDialog {

  PERSONA_API = 'chameleonDBAPI';
  myControl = new FormControl();
  options: string[] = ['Gmail', 'Outlook', 'Facebook','LinkedIn', 'Twitter', 'Instagram',
  'Buffer', 'Yahoo', 'Pinterest', 'Wickr', 'Whatsapp', 'AOL', 'About.Me',
  'Canva', 'iCloud', 'Blur'];

  constructor(public dialogRef: MatDialogRef<AppPersonaPageAccountsDialog>,
              @Inject(MAT_DIALOG_DATA) public data: AccountsDialogData,
              public snackBar: MatSnackBar) {
  }    
}

@Component({
  selector: 'app-persona-page-notes-dialog',
  templateUrl: './persona-page-notes-dialog.component.html'
})
export class AppPersonaPageNotesDialog {
    
  constructor(public dialogRef: MatDialogRef<AppPersonaPageNotesDialog>,
              @Inject(MAT_DIALOG_DATA) public data: NotesDialogData,
              public snackBar: MatSnackBar) {
  }    
}

@Component({
  selector: 'app-persona-page-sessions-dialog',
  templateUrl: './persona-page-sessions-dialog.component.html'
})
export class AppPersonaPageSessionsDialog {
    
  constructor(public dialogRef: MatDialogRef<AppPersonaPageSessionsDialog>,
              @Inject(MAT_DIALOG_DATA) public data: SessionsDialogData,
              public snackBar: MatSnackBar) {
  }    
}

@Component({
  selector: 'app-persona-page-view-dialog',
  templateUrl: './persona-page-view-dialog.component.html'
})
export class AppPersonaPageViewDialog {

  constructor(public dialogRef: MatDialogRef<AppPersonaPageViewDialog>,
              @Inject(MAT_DIALOG_DATA) public data: ViewDialogData,
              public snackBar: MatSnackBar) {
  }    

  displayFile(url) {
    return Storage.get(url);
  }
}



