import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { User } from '../helpers/objects';
import { AuthenticationService } from "../helpers/services";
import { NavigationEnd, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { QuillConfigInterface } from 'ngx-quill-wrapper';
import { Alert } from '../helpers/objects/alert'
import { DatabaseService } from '../helpers/services/database.service';
import { UserLoginLog } from '../helpers/objects/userLoginLog';
import 'rxjs/add/operator/pairwise';

export interface DialogData {
  title: string;
  body: string;
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavComponent implements OnInit {

  addPath = '/alerts';
  currentUser: User;
  currentUserSubscription: Subscription;
  subscription: Subscription;
  source = interval(10000);
  signedIn = false;
  region: string;
  alerts: Alert[] = [];
  notifications: number = 0;
  public groups: string[];

  constructor(private authService: AuthenticationService,
              public snackBar: MatSnackBar,
              public router: Router,
              public dialog: MatDialog,
              public databaseService : DatabaseService) {
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      if (user) {
        this.signedIn = true;
        this.currentUser = user;
        this.groups = user.groups;
      } else {
        this.currentUser = null;
        this.groups = null;
        this.router.navigate(['/login']);
      }
    });

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (this.router.url !== '/login' && this.router.url !== '/') {
          this.setRegion();
        }

      }
    });

    this.subscription = this.source.subscribe( val => this.getAllAlerts());
  }

  

  ngOnInit() {
    this.getAllAlerts();
  }

  

  setRegion() {
    this.region = localStorage.getItem('region');
  }

  onClickSignOut() {
    let loginLog = new UserLoginLog();
    loginLog.username = this.currentUser.username;
    loginLog.action = 'Logout';
    let response = this.databaseService.createUserLoginLog(loginLog)
    if(response){
      sessionStorage.clear();
      this.authService.logout();
    }
  }

  onAlert() {
    this.getAllAlerts().then(r => {
      //console.log(this.alerts)
    });
  }



  async getAllAlerts() {
    try {
      let response = await this.databaseService.getAlertsByUsername(this.currentUser.username);
      if (response) {
        this.alerts = response;
        this.notifications = 0;
        for (let theAlert of response){
          if (theAlert.unread) {
            this.notifications++;
          }
        }
      }
    } catch (error) {
      console.log("error");
    }

  }


  async onClickAlert(alert): Promise<void> {
    let response = await this.databaseService.readAlert(alert.id);
    let response2 = await this.databaseService.getAlertsByUsername(this.currentUser.username);
    if (response2) {
      this.alerts = response2;
      this.notifications = 0;
      for (let theAlert of response2){
        if (theAlert.unread) {
          this.notifications++;
        }
      }
    }
    const dialogRef = this.dialog.open(AlertDialog, {
      width: '700px',
      data: {title: alert.title, body: alert.body}
    });
  }

  onClickAddAlert(): void {
    const dialogRef = this.dialog.open(AddAlertDialog, {
      width: '700px',
      data: {number: this.alerts.length}
    });
  }

  pageSelected(page: string): boolean {
    let selected: boolean = false;
    if (this.router.url.includes(page)) {
      selected = true;
    }
    return selected;
  }
}

@Component({
  selector: 'alert-dialog',
  templateUrl: './alert.dialog.html',
})
export class AlertDialog {

  constructor(
    public dialogRef: MatDialogRef<AlertDialog>,
    @Inject(MAT_DIALOG_DATA) public inputAlert: DialogData) {}


  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'add-alert-dialog',
  templateUrl: './add.alert.dialog.html',
})
export class AddAlertDialog {

  path = '/alerts';
  alerts: object[] = [];
  notifications: number = 0;
  alertTitle: string = '';
  alertBody: string = '';
  currentUser: User;
  currentUserSubscription: Subscription;

  ALERT_QUILL_CONFIG: QuillConfigInterface = {
    theme: 'snow',
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        ['link', 'image'],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
      ]
    },
    placeholder: 'Alert Body'
  };


  constructor(public dialogRef: MatDialogRef<AddAlertDialog>,
              private authService: AuthenticationService,
              @Inject(MAT_DIALOG_DATA) public numberOfAlerts,
              public snackBar: MatSnackBar,
              public databaseService: DatabaseService) {
                this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
                  if (user) {
                    this.currentUser = user;
                  }                 
                });
              }
  
  async getAllAlerts() {
    try {
      let response = await this.databaseService.getAlertsByUsername(this.currentUser.username);
      if (response) {
        this.alerts = response;
        this.notifications = 0;
        for (let theAlert of response){
          if (theAlert.unread) {
            this.notifications++;
          }
        }
      }
    } catch (error) {
      console.log("error");
    }
  }

  onSumbit() {
    if (this.alertTitle == '' || this.alertBody == '') {
      alert('Please fill out both the title and the body');
      return;
    }

    if (!confirm('Are you sure you want to submit this alert? It will be delivered to all users.'))
      return;

    this.getAllAlerts().then( r=> {
      let alert = new Alert();
      alert.username = this.currentUser.username;
      alert.title = this.alertTitle;
      alert.body = this.alertBody;

      const init = {
        body: alert
      };
      
      this.databaseService.createAlert(alert).then(result => {
        this.snackBar.open(`"${alert.title}" has been saved`, 'Close',{duration: 1500});
      }).catch(result => console.log(result));
    }).then(r => this.onNoClick());
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

