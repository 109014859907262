import { Component, OnDestroy, OnInit, Inject} from '@angular/core';
import 'rxjs/Rx';
import { AmplifyService } from 'aws-amplify-angular';
import { AuthenticationService } from '../helpers/services';
import { DatabaseService } from '../helpers/services/database.service';
import { Resource } from '../helpers/objects/resource';
import { FormControl } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';


export interface EditDialogData {
}

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {

  PERSONA_API = 'chameleonDBAPI';
  path = '/resources';
  group = this.authenticationService.currentUserValue.groups;
  resourceData: Resource[];
  editMode: boolean = false;

  constructor(private amplifyService: AmplifyService,
              private authenticationService: AuthenticationService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              public databaseService: DatabaseService) {
  }

  ngOnInit() {
    this.initText();
  }

  async initText() {
    try {
      let response = await this.databaseService.getResources()
      if (response) {
        for(let index of response){

          let keys: string[] = [];
          let imgRegex = /<img src="([^"]+)"/g;
          let match;

          while ((match = imgRegex.exec(index.text)) !== null) {
            if (!keys.includes(match[1])) {
              keys.push(match[1]);
            }
          }

          for(let key of keys){

            const result: any = await Storage.get(key, { download: true });
            if(result){
              const uint8Array = result.Body;
              const decoder = new TextDecoder('utf-8'); 
              const stringResult = decoder.decode(uint8Array);
      
              const prefix = "data:image/png;base64,";

              console.log(key);
              index.text = index.text.replace(new RegExp(`<img src="${key}"`, 'g'), `<img src="${prefix}${stringResult}"`);
            }                  
          }          
        }
          this.resourceData = response;
      }
    } catch (error) {
      console.log(error)
    } 
  }

  dateToString(date){
    let dateLocal = new Date(date);
    return (dateLocal.toLocaleDateString('en-US') + "  " + dateLocal.toLocaleTimeString('en-US'));
  }

  addSection(){
    this.openEditDialog();
  }

  editSection(entry){
    this.openEditDialog("edit", entry);
  }

  openEditDialog(action = 'add', resourceID = undefined): void {
    let theFormData = {};
    if (action === 'edit') {
      theFormData = {title: resourceID.name, body: resourceID.text, action: 'Edit'};
    }
    if (action === 'add') {
      theFormData = {title: '', body: '', action: 'Add'};
    }

    const dialogRef = this.dialog.open(AppResourcesEditDialog, {
      data: theFormData
    });   

    dialogRef.afterClosed().subscribe(result => {
      if (result && action === 'edit'){
        this.editSectionToDatabase(result, resourceID.id);
      }
      if (result && action === 'add'){
        this.addSectionToDatabase(result);
      }
    });


  }

  async resourceImgParser(name, html) {
    let finishedHtml = html;
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const images = Array.from(doc.querySelectorAll('img'));
    
    for (const image of images){

      const UUID = uuidv4();  
      const base64String = image.src.split(',')[1];     
      const response = await Storage.put(`resources/${name}__${UUID}.txt`, base64String);

      if (response && response['key']) {
        console.log(response['key']);
        finishedHtml = finishedHtml.replace(image.src, response['key']);
      };
    }
    return finishedHtml;
  }

  async editSectionToDatabase(result, resourceID) {
    try {
      let resource: Resource = new Resource();
      resource.name = result.title;
      resource.text = result.body;
      resource.id = resourceID;

      let finishedHtml = await this.resourceImgParser(result.title, result.body);
      resource.text = finishedHtml;
      let response = await this.databaseService.updateResource(resource);
      if (response) {
        await this.initText();  
      }      
    } catch (error) {
      console.log("error");
    }
  }

  async addSectionToDatabase(result){
    try {
      let resource: Resource = new Resource();
      resource.name = result.title;
      resource.text = result.body;

      let finishedHtml = await this.resourceImgParser(result.title, result.body);
      resource.text = finishedHtml;
      let response = await this.databaseService.createResource(resource);
      if (response) {
        await this.initText();  
      }      
    } catch (error) {
      console.log("error");
    }
  }

  async deleteSection(id, name){
    let isDelete: boolean = confirm(`Are you sure you want to delete ${name}`);
    if (isDelete){   
      try {
        let response = await this.databaseService.removeResource(id)
          console.log(response)
          if (response) {
            await this.initText()
          }
        } catch (error) {
          console.log(error)
      } 
    }    
  }



}


@Component({
  selector: 'app-resources-edit-dialog',
  templateUrl: './resources-edit-dialog.component.html'
})
export class AppResourcesEditDialog {

  myControl = new FormControl();
  constructor(public dialogRef: MatDialogRef<AppResourcesEditDialog>,
              @Inject(MAT_DIALOG_DATA) public data: EditDialogData,
              public snackBar: MatSnackBar) {
  }    
}
