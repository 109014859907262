import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { Account } from '../helpers/objects/account';
import { Group } from '../helpers/objects';
import { Log } from '../helpers/objects/log';
import { Note } from '../helpers/objects/Note';
import { Persona } from '../helpers/objects/persona';
import { AuthenticationService } from '../helpers/services';
import { MatButtonToggleChange, MatSnackBar } from '@angular/material';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { Storage } from 'aws-amplify';
import { DatabaseService } from '../helpers/services/database.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { pseudoRandomBytes } from 'crypto';

@Component({
  selector: 'app-personas',
  templateUrl: './personas.component.html',
  styleUrls: ['./personas.component.scss']
})
export class PersonasComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, {static: false}) set matPaginator(paginator: MatPaginator){
    this.dataPersonaSource.paginator = paginator;
  };

  toggle: boolean = true; 
  public personaHeads: Group[] = [];
  dataSource = new MatTableDataSource<Group>(this.personaHeads);
  displayedColumns: string[] = ['select', 'options', 'checkedOut', 'name', 'type', 'currentLoc', 'dob', 'pob'];
  selection = new SelectionModel<Group>(true, []);
  personasWithPics = {};
  
  PERSONA_API = 'chameleonDBAPI';

  public path = '/avatar';

  personasHeads: Persona[] = [];
  fullList: Persona[] = [];
  dataPersonaSource = new MatTableDataSource<Persona>(this.personasHeads);
  personasCheckedOut: Persona[] = [];
  personasCheckedIn: Persona[] = [];
  personasCheckedOutFullList: Persona[] = [];
  personasCheckedInFullList: Persona[] = [];

  public db: Group[] = [];
  public username = this.authenticationService.currentUserValue.username;
  public group = this.authenticationService.currentUserValue.groups;
  region: string;
  emptyPersonas: boolean = false;
  regionList = ['View All'];
  selectedRegion: any;
  myControl = new FormControl();
  options: Group[] = [];
  find: boolean = false;
  groupCheckOut = [];
  groupCheck: boolean = false;
  allDataLoaded: boolean = false;
  public DB: Group[] = [];

  constructor(private router: Router,
              private amplifyService: AmplifyService,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              public snackBar: MatSnackBar,
              private databaseService: DatabaseService) {
    this.region = localStorage.getItem('region');
    if (sessionStorage.getItem('gridView') != 'true') {
      this.toggle = false;
    }
  }

  async ngOnInit() {
    this.getPersonaData(this.region).then(result => this.initProfilePics()).then(result => this.allDataLoaded = true);
    let regions = await this.databaseService.getRegions();
    for (let region of regions) {
      this.regionList.push(region);
    }
  }

  ngOnDestroy() {
    sessionStorage.setItem('gridView', String(this.toggle));
  }

  toggleView(change: MatButtonToggleChange) {
    this.toggle = change.value;
  }

  /**
   * get size of personas
   */
  getPersonaLength(): number {
    return this.personaHeads.length;
  }

  /**
   * Applying filter to the personas and to the pages on the bottom
   */
  applyFilter(filterValue: string) {
    this.dataPersonaSource.filter = filterValue.trim().toLowerCase();

    if (this.dataPersonaSource.paginator) {
      this.dataPersonaSource.paginator.firstPage();
    }
  }

  filterTiles(filterValue: string) {
    this.personasCheckedIn = this.personasCheckedInFullList.filter(persona => this.matches(persona, filterValue));
    this.personasCheckedOut = this.personasCheckedOutFullList.filter(persona => this.matches(persona, filterValue));
  }

  matches(persona: Persona, filterValue: string): boolean {
    let match: boolean = false;
    match = persona.name.split('_')[1].toLowerCase().includes(filterValue);
    return match;
  }

  matchesRegionFilter(persona: Persona, region: string): boolean {
    let match: boolean = false;
    match = persona.region.includes(region);
    return match;
  }

  /**
   * If all of the rows are selected for group in/checkout
   */
  isAllSelected(): boolean {
    return (this.selection.selected.length === this.dataSource.data.length);
  }

  /** 
   * The label for the checkbox on the passed row 
   */
  checkboxLabel(row?: Group): string {
    return (!row) ? `${this.isAllSelected() ? 'select' : 'deselect'} all` : `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }

  private selectRow($event, dataSource) {
    if ($event.checked === true) {
      this.groupCheckOut.push(dataSource.name);
    } else {
      for (let n of this.groupCheckOut) {
        if (dataSource.name === n) {
          let i = this.groupCheckOut.indexOf(n);
          this.groupCheckOut.splice(i, 1);
        }
      }
    }
  }

  /**
   * Checking out selected methods
   */
  checkThemAllOut() {
    for (let persona of this.personasHeads) {
      for (let check of this.groupCheckOut) {
        if (persona.name === check) {
          this.databaseService.updateCheckoutStatus((persona.checkedOut ? false : true), persona.id);
        }
      }
    }
    this.groupCheckOut = [];
    this.selection.clear();
    this.getPersonaData(this.region);
  }

  async getPersonaData(region: string): Promise<Persona[]> {
    let personas: Persona[] = [];
    let personasHeadsTemp: Persona[] = [];
    let personasCheckedOutTemp: Persona[] = [];
    let personasCheckedInTemp: Persona[] = [];
    try {
      if (this.group.includes('Super-Admin')) {
        personas = await this.databaseService.getAllPersonas();
      } else {
        personas = await this.databaseService.getPersonasByRegion(this.region);
      }
      for (let persona of personas) {
        personasHeadsTemp.push(persona);
        if (persona.checkedOut) {
          personasCheckedOutTemp.push(persona);
        } else {
          personasCheckedInTemp.push(persona);
        }
      }

      this.personasHeads = personasHeadsTemp;
      this.personasCheckedOut = personasCheckedOutTemp;
      this.personasCheckedIn = personasCheckedInTemp;
      this.personasCheckedOutFullList = this.personasCheckedOut;
      this.personasCheckedInFullList = this.personasCheckedIn;
    } catch (error) {
      console.log("error");
    }
    this.fullList = this.personasHeads;
    this.dataPersonaSource = new MatTableDataSource<Persona>(this.personasHeads);
    return personas;
  }  

  /**
   * This sends the user to the page the creates a new persona
   */
  onCreate() {
    this.router.navigate(['/register']);
  }

  /**
   * Adds checkin checkout time to logs
   */
  addCheckInOutLog(inputName: string, checkingOut: boolean, persona: []) {
    try {
      if (checkingOut) {
        persona['logs'].push({'user': inputName, 'type': 'Checking Out', 'dateTime': this.getDate()});
      } else {
        persona['logs'].push({'user': inputName, 'type': 'Checking In', 'dateTime': this.getDate()});
      }
    } catch (e) {
      console.log('error ' + e);
    }

    return persona;
  }

  private getDate() {
    let d = new Date();
    //Getting Date in consistent padding format
    let datestring = d.getFullYear() + "." + ("0" + (d.getMonth()+1)).slice(-2) + "." +
      ("0" + d.getDate()).slice(-2) + "_" +
      ("0" + d.getHours()).slice(-2) + ":" +
      ("0" + d.getMinutes()).slice(-2) + ":" +
      ("0" + d.getSeconds()).slice(-2);
    return datestring;
  }

  /**
   * Changes the personas check in/out status 
   */
  async checkInOrOut(checkedOut: boolean, persona: Persona) {
    try {
      this.databaseService.updateCheckoutStatus((checkedOut ? false : true), persona.id).then(result => {
        let temp: Persona[];
        let log: Log = new Log();
        log.personaId = persona.id;
        log.user = persona.user;
        if (checkedOut) {
          temp = [];
          persona.checkedOut = false;
          this.personasCheckedIn.push(persona);
          for (let p of this.personasCheckedOut) {
            if (p.id !== persona.id) 
              temp.push(p);
          }
          this.personasCheckedOut = temp;
          log.type = 'Checking In';
          log.dateTime = new Date();
          this.databaseService.createLog(log);
        } else {
          temp = [];
          persona.checkedOut = true;
          this.personasCheckedOut.push(persona);
          for (let p of this.personasCheckedIn) {
            if (p.id !== persona.id) 
              temp.push(p);
          }
          this.personasCheckedIn = temp;
          log.type = 'Checking Out';
          log.dateTime = new Date();
          this.databaseService.createLog(log);
        }
        this.getPersonaData(this.region);
      });
    } catch (error) {
      console.log("error" + error);
    }
    
  }

  /**
   * Deletes the persona from the database
   */
  public async deletePersona(person: Persona): Promise<boolean> {
    let response = false;
    let isDelete: boolean = confirm(`Are you sure you want to delete ${person.name.split('_')[1]}?`);
    if (isDelete) {
      let delPersonaResp = await this.databaseService.removePersona(person.id);
      let delAccountResp = await this.databaseService.removeAccountsByPersona(person.id);
      let delNotesResp = await this.databaseService.removeNotesByPersona(person.id);
      let delLogsResp = await this.databaseService.removeLogsByPersona(person.id);

      if (delPersonaResp && delAccountResp && delNotesResp && delLogsResp) {
        if (!delPersonaResp.error && !delAccountResp.error && !delNotesResp.error && !delLogsResp.error) {
          response = true;
        } else {
          console.log('ERROR deleting Avatar');
        }
      }
      this.getPersonaData(this.region);
    }

    return response;
  }

  /**
   * This views the personas information
   */
  onView(name: string, id: number) {
    this.router.navigate( [`/avatars/view/${name}/${id}`]);
  }

  /**
   * Sends the persona selected to the edit page
   */
  onEdit(name: string, id: number) {
    this.router.navigate([`/avatars/edit/${name}/${id}`]);
  }

  /**
   * Filter regions
   */
  async onRegion(region: string) {
    if (region !== 'View All') {
      this.personasCheckedIn = this.personasCheckedInFullList.filter(persona => this.matchesRegionFilter(persona, region));
      this.personasCheckedOut = this.personasCheckedOutFullList.filter(persona => this.matchesRegionFilter(persona, region));
      this.dataPersonaSource = new MatTableDataSource<Persona>(this.personasHeads.filter(persona => this.matchesRegionFilter(persona, region)));
    } else {
      this.personasCheckedIn = this.personasCheckedInFullList;
      this.personasCheckedOut = this.personasCheckedOutFullList;
      this.dataPersonaSource = new MatTableDataSource<Persona>(this.personasHeads);
    }
  }

  /**
   * Calls to set the src of each profile photo for each persona
   * Stores in JSON object to be called in html
   */
  initProfilePics() {
    for (let persona of this.personasHeads) {
      let folderName: string = '';
      if (persona.profilePicPath) {
        folderName = persona.profilePicPath.replace('/profilepic.png', '');
        Storage.list(folderName).then(result => {
          for (let entry of result) {
            if (String(entry.key).includes('profilepic.png')) {
              Storage.get(persona.profilePicPath).then(result => {
                let src = String(result);
                this.personasWithPics[persona.profilePicPath] = src;
              });
            }
          }
        }).catch(err => console.log('error getting avatar pics', err));
      } else {
        Storage.get('default_profile_pic.png').then(result => {
          let src = String(result);
          this.personasWithPics[persona.profilePicPath] = src;
        });
      }
    }
  }
}
