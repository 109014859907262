import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModule } from '@angular/core';
import { DatabaseService } from '../helpers/services';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import jsPDF from 'jspdf';
import 'jspdf-autotable';



export interface UserLoginLogs {
  username: string;
  action: string;
  timestamp: Date;
}

const ELEMENT_DATA: UserLoginLogs[] = [
  {"username": 'default1', "action": 'login', 'timestamp': new Date()},
  {"username": 'default2', "action": 'login', 'timestamp': new Date()},
  {"username": 'default3', "action": 'login', 'timestamp': new Date()},
]


@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss'],
  
})

@NgModule({
  imports: [
    MatTabsModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule
  ],
})
export class ManagementComponent implements OnInit {

  path = '/management';
  displayedColumns: string[] = ['username', 'action', 'timestamp'];
  dataSource = new MatTableDataSource<UserLoginLogs>(ELEMENT_DATA);

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(public databaseService: DatabaseService) {
  }

  async ngOnInit() {
    // this.dataSource = new MatTableModule(ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
    let tempDataSource = await this.databaseService.getUserLoginLogs()
    if(tempDataSource) {
      tempDataSource.forEach((i) => {
        i.timestamp = new Date(i.timestamp);
      })
      this.dataSource = new MatTableDataSource<UserLoginLogs>(tempDataSource);
      this.dataSource.paginator = this.paginator;
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string){
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportCSV() {
    let csv = 'Username, Action, Timestamp\n';
    for (let log of this.dataSource.filteredData) {
      csv += log.username + ',' + log.action + ',' + log.timestamp + '\n';
    }

    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'log_export_' + new Date().getTime() + '_' + '.csv';
    hiddenElement.click();
  }

  exportPDF() {
    let head = [['Username', 'Action', 'Timestamp']];
    let data = [];
    let currentTimestamp: Date = new Date();
    let fileName = 'login_log_export_' + currentTimestamp.getTime() + '_' + '.pdf';
    let currentTimeString = currentTimestamp.toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit',
      day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })
    let title = 'Log Export on ' + currentTimeString;

    for (let log of this.dataSource.filteredData) {
      let row = [ log.username, log.action, log.timestamp ];
      data.push(row);
    }

    var doc = new jsPDF();

    doc.setFontSize(12);
    doc.text(title, 11, 8);
    doc.setFontSize(8);
    doc.setTextColor(100);

    (doc as any).autoTable({
      head: head,
      body: data,
      theme: 'plain',
      didDrawCell: data => {}
    })

    // Download PDF document
    doc.save(fileName);
  }

}
