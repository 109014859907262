import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AmplifyService } from 'aws-amplify-angular';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-resource-editing',
  templateUrl: './resource-editing.component.html',
  styleUrls: ['./resource-editing.component.scss']
})
export class ResourceEditingComponent implements OnInit {
  PERSONA_API = 'chameleonDBAPI';
  editorForm: FormGroup;
  path = '/resources';

  buildEditorContent: string;
  useEditorContent: string;
  creationEditorContent: string;
  recordingEditorContent: string;

  constructor(private amplifyService: AmplifyService,
              public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.editorForm = new FormGroup({'build-editor' : new FormControl(null)});
    this.editorForm.addControl('use-editor', new FormControl(null));
    this.editorForm.addControl('creation-editor', new FormControl(null));
    this.editorForm.addControl('recording-editor', new FormControl(null));

    this.initEditors();
  }

  async onSubmit(type): Promise<Object> {
    try {
      let content:string = null;
      let editor: string;
      if (type == 'build-editor') {
        content = this.buildEditorContent;
        editor = 'Avatar Build Tips';
      } else if (type == 'use-editor') {
        content = this.useEditorContent;
        editor = 'Avatar Use Tips'
      } else if (type == 'creation-editor') {
        content = this.creationEditorContent;
        editor = 'Avatar Creation Tips';
      } else if (type == 'recording-editor') {
        content = this.recordingEditorContent;
        editor = 'Avatar Recording Practices'
      } else {
        return;
      }

      content = content.replace(/<p><br><\/p>/g, "");

      let result = { 'name': type, 'content': content };

      const init = {
        body: result
      };

      this.amplifyService.api().post(this.PERSONA_API, this.path, init).then(result => {
        this.snackBar.open(`"${editor}" has been saved`, 'Close',{duration: 1500});
      }).catch(result => console.log(result));
    } catch (e) {
      console.log("error uploading html file:", e);
    }

    return;
  }

  async initEditors() {
    try {
      this.amplifyService.api().get(this.PERSONA_API, '/resources').then(result => {
        for (let res of result) {
          if (res.name === 'build-editor')
            this.buildEditorContent = res.content;
          else if (res.name === 'creation-editor')
            this.creationEditorContent = res.content;
          else if (res.name === 'use-editor')
            this.useEditorContent = res.content;
          else if (res.name === 'recording-editor')
            this.recordingEditorContent = res.content;
        }
      }).catch(result => console.log(result));
    }
    catch (e) {
      console.log("error getting html file:", e);
    }
  }
}
