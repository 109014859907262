import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from "../helpers/must-match";
import { AlertService, AuthenticationService } from '../helpers/services';
import { MatDialog } from '@angular/material/dialog';
import { AmplifyService } from "aws-amplify-angular";
import { DatabaseService } from '../helpers/services/database.service';
import { getLocaleTimeFormat } from '@angular/common';
import { UserLoginLog } from '../helpers/objects/userLoginLog'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  passwordForm: FormGroup;
  preForgotPassForm: FormGroup;
  forgotPasswordForm: FormGroup;
  submitted = false;
  returnUrl: string;
  currentForm: string = 'login';
  valFirst: boolean = false;
  valFirstContent: string = '';
  valSecond: boolean = false;
  valSecondContent: string = '';
  PERSONA_API = 'chameleonDBAPI';
  correct: boolean;
  username: string;

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private alertService: AlertService,
              public dialog: MatDialog,
              private amplifyService: AmplifyService,
              public databaseService: DatabaseService) {
    // Redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/home']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required]
    });

    this.passwordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.pattern('^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$')]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });
    this.preForgotPassForm = this.formBuilder.group({
      username:['', [Validators.required]]
    });
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get p() {
    return this.passwordForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      alert("You must enter a username and password");
      return;
    }

    this.authenticationService.login(this.f.username.value, this.f.password.value).then(data => {
      if (data['cognitoUser']['challengeName'] && data['cognitoUser']['challengeName'] === 'NEW_PASSWORD_REQUIRED') {
        console.log("Need to reset the password");
        this.currentForm = 'password';
        this.submitted = false;
      } else {
        let loginLog: UserLoginLog = new UserLoginLog();
        loginLog.username = data.username;
        loginLog.action = 'Login';        
        this.databaseService.createUserLoginLog(loginLog)
        this.setUserStorage();
        this.router.navigate(['/home']);
      }
    }, error => {
      alert("Something isn't right here, check your username and password");
    });
  }

  onPasswordSubmit() {
    this.submitted = true;

    if (this.passwordForm.invalid) {
      return;
    }

    let newPassword = this.p.newPassword.value;
    this.authenticationService.setPassword(newPassword).then(data => {
      alert("Password changed successfully.");
      this.setUserStorage();
      this.currentForm = '';
      this.submitted = false;
      this.router.navigate(['/home']);
    }, error => {
      alert("Error has occurred");
    });
  }

  // Not used
  get forgot() {
    return this.forgotPasswordForm.controls;
  }

  get forgotNew() {
    return this.forgotPasswordForm.get('newPassword');
  }

  get forgotConf() {
    return this.forgotPasswordForm.get('confirmPassword');
  }


  createForgotPasswordForm(username: string) {
    this.forgotPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required],
      code:['']
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });
    this.forgotPasswordForm.patchValue({
      user: username
    });
    this.currentForm = 'forgotPassword';
  }


  onForgotPasswordCodeSubmit() {
    let password = this.forgotPasswordForm.value.newPassword;
    let code = this.forgotPasswordForm.value.code;
    this.authenticationService.resetPasswordCodeSubmit(this.username,code,password).then((data) => {
      if (data) {
        alert("You have successfully changed your password! Hopefully you will remember this one.");
        this.authenticationService.logout();
        this.currentForm = 'login';
      } else {
        this.alertService.error("An error occurred. Please check the console for more details.");
      }
    }, error => {
      this.alertService.error(error['message']);
    });
  }

  valFirstPass(password: string) {
    let capital = /[A-Z]/g;
    let lowercase = /[a-z]/g;
    let number = /[0-9]/g;
    let length = /(?=.{20,})/g;
    let specialChar = /[^A-Za-z0-9]/g;

    this.valFirstContent = password;

    if (password.match(capital) && password.match(lowercase) && password.match(number) && password.match(length) && password.match(specialChar)) {
      this.valFirst = true;
    } else {
      this.valFirst = false;
    }


  }

  valSecondPass(password: string) {
    this.valSecondContent = password;
  }

  setUserStorage() {
    // Setting the username
    localStorage.setItem('username', this.authenticationService.currentUserValue.username);

    // Setting the users region
    let region: string;
    let group = this.authenticationService.currentUserValue.groups;
    if (group.includes("Miami")) {
      region = "Miami";
    } else if (group.includes("NYC")) {
      region = "NYC";
    } else if (group.includes("Atlanta")) {
      region = "Atlanta";
    } else if (group.includes("Boston")) {
      region = "Boston";
    } else if (group.includes("Chicago")) {
      region = "Chicago";
    } else if (group.includes("Dallas")) {
      region = "Dallas";
    } else if (group.includes("HQS")) {
      region = "HQS";
    } else if (group.includes("Kansas-City")) {
      region = "Kansas-City";
    } else if (group.includes("Los Angeles")) {
      region = "Los-Angeles";
    } else if (group.includes("Philadelphia")) {
      region = "Philadelphia";
    } else if (group.includes("San-Francisco")) {
      region = "San-Francisco";
    } else if (group.includes('blackhorse')) {
      region = "blackhorse";
    } else if (group.includes('Super-Admin')) {
      region = 'admin';
    }

    localStorage.setItem('region', region);
    sessionStorage.setItem('gridView', 'true');
  }
}
